<template>
  <div class="summary-item">
    <div class="icon-wrapper">
      <AppIcon name=" ">
        <IconSmallClockAlt v-if="icon === 'clock'" />
        <IconCoins v-if="icon === 'coins'" />
        <IconReceipt v-if="icon === 'receipt'" />
      </AppIcon>
    </div>
    <span class="title">{{ title }}</span>
    <span class="item-value" data-test-id="qt-summary-item-span-value">
      {{ value }}
    </span>
  </div>
</template>

<script>
import { IconSmallClockAlt, IconCoins, IconReceipt } from '@galileo/assets/icons/vue'
import { AppIcon, AppDescriptionListItem } from '@oen.web.vue2/ui'

export default {
  name: 'QuickTransferSummaryItem',
  components: {
    AppDescriptionListItem,
    AppIcon,
    IconSmallClockAlt,
    IconCoins,
    IconReceipt,
  },
  props: {
    icon: {
      type: String,
      required: false,
      default: '',
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    value: {
      required: true,
      default: '',
    },
  },
}
</script>
<style scoped>
.summary-item {
  @apply flex flex-row gap-2 items-start leading-4 mb-3;
}
.icon-wrapper {
  @apply flex items-start justify-center;
}
.item-value {
  @apply text-xs text-right font-semibold text-grayblue-400 flex-shrink w-0 flex-grow;
}
.title {
  @apply text-xs text-gray-text;
}
</style>

<template>
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      v-if="!hideBackground"
      d="M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z"
      fill="#DAE8F5"
    />
    <path
      d="M16.25 17.75H11.75C11.5511 17.75 11.3603 17.671 11.2197 17.5303C11.079 17.3897 11 17.1989 11 17V12.5C11 12.3011 11.079 12.1103 11.2197 11.9697C11.3603 11.829 11.5511 11.75 11.75 11.75C11.9489 11.75 12.1397 11.829 12.2803 11.9697C12.421 12.1103 12.5 12.3011 12.5 12.5V14.7359C13.9503 13.1 16.4947 11 20 11C24.0144 11 26.4294 13.3691 26.5306 13.4694C26.6716 13.6099 26.751 13.8006 26.7514 13.9996C26.7517 14.1986 26.673 14.3896 26.5325 14.5306C26.392 14.6716 26.2013 14.751 26.0023 14.7514C25.8032 14.7517 25.6122 14.673 25.4713 14.5325C25.4459 14.5072 23.3675 12.5 20 12.5C16.7188 12.5 14.3534 14.8109 13.1975 16.25H16.25C16.4489 16.25 16.6397 16.329 16.7803 16.4697C16.921 16.6103 17 16.8011 17 17C17 17.1989 16.921 17.3897 16.7803 17.5303C16.6397 17.671 16.4489 17.75 16.25 17.75ZM28.25 22.25H23.75C23.5511 22.25 23.3603 22.329 23.2197 22.4697C23.079 22.6103 23 22.8011 23 23C23 23.1989 23.079 23.3897 23.2197 23.5303C23.3603 23.671 23.5511 23.75 23.75 23.75H26.8025C25.6466 25.1891 23.2812 27.5 20 27.5C16.6325 27.5 14.5541 25.4928 14.5287 25.4675C14.3878 25.327 14.1968 25.2483 13.9977 25.2486C13.7987 25.249 13.608 25.3284 13.4675 25.4694C13.327 25.6104 13.2483 25.8014 13.2486 26.0004C13.249 26.1994 13.3284 26.3901 13.4694 26.5306C13.5706 26.6309 15.9856 29 20 29C23.5053 29 26.0497 26.9 27.5 25.2641V27.5C27.5 27.6989 27.579 27.8897 27.7197 28.0303C27.8603 28.171 28.0511 28.25 28.25 28.25C28.4489 28.25 28.6397 28.171 28.7803 28.0303C28.921 27.8897 29 27.6989 29 27.5V23C29 22.8011 28.921 22.6103 28.7803 22.4697C28.6397 22.329 28.4489 22.25 28.25 22.25Z"
      fill="#006CE0"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconConvertAction',
  props: {
    hideBackground: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

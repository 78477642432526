<template>
  <AppListItem>
    <template #figure>
      <AppFigure class="xe-figure" theme="gray"> {{ recipient.initials }} </AppFigure>
      <CircularFlag :currency-code="recipient.currency" class="country-flag" include-border />
    </template>
    <template #left>
      <AppListItemTitle>{{ recipient.fullName | maxLength(30) }}</AppListItemTitle>
      <div v-if="recipient.bankAccountNumber">
        <AppListItemCaption>
          {{ getCountryName(recipient.country) }} ({{ recipient.currency }})
          {{ '••' + recipient.bankAccountNumber.slice(recipient.bankAccountNumber.length - 4) }}
        </AppListItemCaption>
      </div>
    </template>
    <template #action>
      <button @click="viewRecipient">
        <AppIcon name=" ">
          <IconEdit />
        </AppIcon>
      </button>
    </template>
  </AppListItem>
</template>

<script>
import { useCountriesStore } from '@/stores/countries'

import {
  AppListItem,
  AppFigure,
  AppIcon,
  AppListItemTitle,
  AppListItemCaption,
} from '@oen.web.vue2/ui'

import EllipsisWrapper from '@galileo/components/Views/Recipient/EllipsisWrapper.vue'
import { IconEdit } from '@oen.web.vue2/icons'
import { getFlag } from '@galileo/composables/useFlag'
import { getCountryName } from '@galileo/utilities/filters'
import CircularFlag from '@galileo/components/CircularFlag/CircularFlag.vue'

export default {
  name: 'RecipientOption',
  emits: ['view-recipient'],
  components: {
    CircularFlag,
    AppListItem,
    AppFigure,
    AppIcon,
    AppListItemTitle,
    AppListItemCaption,
    EllipsisWrapper,
    IconEdit,
    getCountryName,
  },
  props: {
    recipient: {
      type: Object,
      required: true,
      default: {},
    },
  },
  setup(props, { emit }) {
    const countriesStore = useCountriesStore()

    const viewRecipient = (event) => {
      if (props.recipient) {
        emit('view-recipient', props.recipient.id)
      }
      event.stopPropagation()
    }

    return {
      getFlag,
      viewRecipient,
      getCountryName,
    }
  },
}
</script>

<style scoped>
::v-deep .list-item-content {
  @apply py-0;
}
::v-deep .list-item-figure {
  @apply flex;
}
.xe-figure {
  @apply w-10 h-10;
  border-radius: 50%;
}
.country-flag {
  @apply self-end mt-6 -ml-3;
}
::v-deep .flag-wrapper {
  border-radius: 0.125rem;
  left: -3px;
  transform: scale(1.2);
}
</style>

<template>
  <div class="flex flex-col gap-5">
    <QTStylesWrapper class="qt-app-input-dropdown">
      <AppInputDropdown
        class="reason-for-transfer"
        v-model="paymentReferenceStore.paymentReferenceForm.reasonForTransferOption.$value"
        :placeholder="$t('QuickTransferReasonForTransfer.PlaceholderText').value"
        :border="false"
        :options="reasonForTransferList"
        analytics-name="reason-for"
        selected-display-field="text"
        selected-value-field="value"
        :disabled="disableReasonForTransferFields"
        :validation="paymentReferenceStore.paymentReferenceForm.reasonForTransferOption"
      >
        <template #selected="{ option }">
          <div class="reason-text">
            <p class="sub-title">
              {{ $t('QuickTransferReasonForTransfer.DropdownLabelText').value }}
            </p>
            <p>{{ option.text }}</p>
          </div>
        </template>
      </AppInputDropdown>
    </QTStylesWrapper>
    <QTStylesWrapper v-if="isOtherReason" class="qt-app-input-text">
      <AppInputText
        v-model="paymentReferenceStore.paymentReferenceForm.reasonForTransferText.$value"
        :label="$t('QuickTransferReasonForTransfer.OtherReasonLabel').value"
        analytics-name="reason-for-transfer-other"
        :focused="true"
        :validation="paymentReferenceStore.paymentReferenceForm.reasonForTransferText"
        :disabled="disableReasonForTransferFields"
      />
    </QTStylesWrapper>
  </div>
</template>

<script>
import {
  useI18nStore,
  usePaymentReferenceStore,
  useSendMoneyStore,
  useQuickTransferStore,
} from '@galileo/stores'
import { computed } from '@vue/composition-api'
import {
  AppInputDropdown,
  AppInputText,
  AppInputToggle,
  AppInputRadioButtons,
} from '@oen.web.vue2/ui'
import QTStylesWrapper from '@galileo/components/Views/QuickTransfer/StyleWrapperComponents/QTStylesWrapper'

export default {
  name: 'reasonForTransferSelector',
  components: {
    AppInputDropdown,
    AppInputText,
    AppInputToggle,
    AppInputRadioButtons,
    QTStylesWrapper,
  },
  emits: ['input', 'onSelect'],
  props: {
    isCalculatingQuote: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const paymentReferenceStore = usePaymentReferenceStore()
    const sendMoneyStore = useSendMoneyStore()
    const quickTransferStore = useQuickTransferStore()

    const isProcessingTransaction = computed(() => quickTransferStore.isProcessingTransaction)

    const isSelectedRecipient = computed(() => sendMoneyStore.form.recipient?.id)

    const isOtherReason = computed(() =>
      paymentReferenceStore.paymentReferenceForm.reasonForTransferOption.$value.includes('_OTHER')
    )

    const disableReasonForTransferFields = computed(
      () =>
        !isSelectedRecipient.value ||
        isProcessingTransaction.value ||
        isReasonForTransferDropdownDisabled.value ||
        props.isCalculatingQuote
    )

    const disableReferenceToggle = computed(
      () => !isSelectedRecipient.value || isProcessingTransaction.value || props.isCalculatingQuote
    )

    const disableRefrenceTextField = computed(
      () =>
        paymentReferenceStore.getIsPaymentReferenceInputDisabled ||
        isProcessingTransaction.value ||
        props.isCalculatingQuote
    )

    const reasonForTransferList = computed(() => paymentReferenceStore.reasonForTransferList)

    const isReasonForTransferDropdownDisabled = computed(() => {
      if (reasonForTransferList.value.length === 1) {
        paymentReferenceStore.paymentReferenceForm.reasonForTransferOption.$value =
          reasonForTransferList.value[0].value
        return true
      }
      return false
    })

    const referenceOptions = computed(() =>
      paymentReferenceStore.referenceOptions.map((option) => ({ ...option, label: option.text }))
    )

    return {
      isReasonForTransferDropdownDisabled,
      isProcessingTransaction,
      disableReasonForTransferFields,
      disableReferenceToggle,
      disableRefrenceTextField,
      $t,
      paymentReferenceStore,
      reasonForTransferList,
      isOtherReason,
      isSelectedRecipient,
      referenceOptions,
    }
  },
}
</script>

<style scoped>
.reason-for-transfer.input {
  @apply mb-0;
}

::v-deep .reason-for-transfer {
  .input-wrapper {
    @apply rounded-lg bg-gray-400;
    .reason-text {
      @apply relative h-full pt-5;
      .sub-title {
        @apply absolute top-1 left-0 leading-4;
        z-index: 1;
      }
    }
  }
  .p-dropdown-items {
    @apply px-2;
    .p-dropdown-item {
      @apply mb-3 rounded;
      .dropdown-option {
        @apply py-2;
      }
    }
  }
  .prime-dropdown.input-input.p-dropdown.p-component.p-inputwrapper.p-disabled.p-inputwrapper-filled
    p {
    @apply text-gray-disabled;
  }
}

::v-deep .prime-dropdown {
  @apply h-14;
  background: #e8ebee;
  color: #8890a0;
  .selected-value {
    @apply overflow-visible;
  }
}

::v-deep .prime-dropdown.p-inputwrapper {
  padding: 0 0.75rem;
}
::v-deep .p-dropdown-trigger[aria-expanded='true'] {
  color: unset;
}

::v-deep .input--disabled .select-placeholder {
  @apply text-gray-disabled;
}
::v-deep .prime-dropdown .p-dropdown-panel .p-dropdown-items-wrapper {
  @apply mt-3;
  scrollbar-width: thin;
}
::v-deep .dropdown-option {
  @apply text-base font-semibold;
  color: rgba(49, 63, 91, 1);
}
::v-deep .p-dropdown-items {
  @apply p-4;
}
::v-deep .p-dropdown-item {
  @apply mb-4;
}
.reason-text {
  @apply text-base font-normal;
  color: rgba(49, 63, 91, 1);
  .sub-title {
    @apply text-xs font-normal;
  }
}
</style>






















import { defineComponent } from '@vue/composition-api'
import { AppButton } from '@oen.web.vue2/ui'
import IndustryEddForm from './IndustryEddForm.vue'

import InformationAppModal, { ICON_TYPE } from '@galileo/components/InformationAppModal/InformationAppModal.vue'
import { useI18nStore } from '@galileo/stores'

export default defineComponent({
  name: 'BasicEddFieldsModal',

  components: {
    InformationAppModal,
    AppButton,
    IndustryEddForm,
  },

  props: {
    open: {
      type: Boolean,
      required: true,
    },

    disabled: {
      type: Boolean,
      default: false,
    }
  },

  emits: ['on-subindustry-select', 'on-close'],

  setup() {
    const { $t } = useI18nStore()

    return {
      type: ICON_TYPE.ICON_INFO_ALT,
      $t,
    }
  },
})

<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.96896 20.0312L3.96896 17.0312C3.82823 16.8905 3.74916 16.6996 3.74916 16.5006C3.74916 16.3016 3.82823 16.1107 3.96896 15.97C4.10969 15.8292 4.30056 15.7502 4.49958 15.7502C4.69861 15.7502 4.88948 15.8292 5.03021 15.97L6.74958 17.6903L6.74958 4.50059C6.74958 4.30168 6.8286 4.11091 6.96925 3.97026C7.1099 3.82961 7.30067 3.75059 7.49958 3.75059C7.69849 3.75059 7.88926 3.82961 8.02991 3.97026C8.17057 4.11091 8.24958 4.30168 8.24958 4.50059L8.24958 17.6903L9.96896 15.97C10.1097 15.8292 10.3006 15.7502 10.4996 15.7502C10.6986 15.7502 10.8895 15.8292 11.0302 15.97C11.1709 16.1107 11.25 16.3016 11.25 16.5006C11.25 16.6996 11.1709 16.8905 11.0302 17.0312L8.03021 20.0312C7.96055 20.1009 7.87784 20.1563 7.78679 20.194C7.69574 20.2318 7.59814 20.2512 7.49958 20.2512C7.40102 20.2512 7.30343 20.2318 7.21238 20.194C7.12133 20.1563 7.03861 20.1009 6.96896 20.0312ZM12.969 6.96996C12.8282 7.1107 12.7492 7.30157 12.7492 7.50059C12.7492 7.69961 12.8282 7.89048 12.969 8.03122C13.1097 8.17195 13.3006 8.25101 13.4996 8.25101C13.6986 8.25101 13.8895 8.17195 14.0302 8.03122L15.7496 6.3109L15.7496 19.5006C15.7496 19.6995 15.8286 19.8903 15.9693 20.0309C16.1099 20.1716 16.3007 20.2506 16.4996 20.2506C16.6985 20.2506 16.8893 20.1716 17.0299 20.0309C17.1706 19.8903 17.2496 19.6995 17.2496 19.5006L17.2496 6.3109L18.969 8.03122C19.1097 8.17195 19.3006 8.25101 19.4996 8.25101C19.6986 8.25101 19.8895 8.17195 20.0302 8.03121C20.1709 7.89048 20.25 7.69961 20.25 7.50059C20.25 7.30157 20.1709 7.1107 20.0302 6.96996L17.0302 3.96997C16.9606 3.90023 16.8778 3.84491 16.7868 3.80717C16.6957 3.76943 16.5981 3.75 16.4996 3.75C16.401 3.75 16.3034 3.76943 16.2124 3.80717C16.1213 3.84491 16.0386 3.90023 15.969 3.96997L12.969 6.96996Z"
      fill="#636E82"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconSwapCurrencies',
}
</script>

























import { defineComponent, onMounted } from '@vue/composition-api';
import { AppInputDropdown } from '@oen.web.vue2/ui'

import { useIndustries } from '@galileo/composables/useIndustries/use-industries';
import { useI18nStore } from '@galileo/stores';

export default defineComponent({
  name: 'IndustryEddForm',

  components: {
    AppInputDropdown,
  },

  emits: ['on-subindustry-select'],

  setup(_, { emit }) {
    const {
      fetchIndustries,
      industries,
      subIndustries,
      selectedIndustry,
      selectedSubIndustry,
    } = useIndustries()

    onMounted(() => {
      fetchIndustries()
    })

    const onSubIndustryChange = ({ value }: { value: number }) => {
      emit('on-subindustry-select', value)
    }

    const { $t } = useI18nStore()

    return {
      industries,
      subIndustries,
      selectedIndustry,
      selectedSubIndustry,

      onSubIndustryChange,
      $t,
    }
  }
})

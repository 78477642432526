<template>
  <div class="dropdown-title">
    <div class="bank-name">
      <p class="sub-title">{{ $t('ComponentBankSelectionDropdown.BankAccountText').value }}</p>
      <p>
        {{ bankAccount.alias || bankAccount.accountName }} {{ bankAccount.maskedAccountNumber }}
      </p>
    </div>
  </div>
</template>

<script>
import { useI18nStore } from '@galileo/stores'

export default {
  name: 'BankAccountSelectedOption',
  props: {
    bankAccount: {
      type: Object,
      required: true,
      default: {},
    },
  },
  setup() {
    const { $t } = useI18nStore()

    return {
      $t,
    }
  },
}
</script>

<style scoped>
.dropdown-title {
  @apply flex gap-4;
}
.bank-name {
  @apply flex flex-col items-start;
  @apply text-base font-medium;
  line-height: 1.5;
  color: #313f5b;
}
.sub-title {
  @apply text-xs;
  color: #636e82;
}
</style>

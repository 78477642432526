<template>
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      v-if="!hideBackground"
      d="M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z"
      fill="#DAE8F5"
    />
    <path
      d="M28.2308 19.0786C28.2024 18.9584 28.1448 18.847 28.063 18.7545C27.9812 18.6619 27.8779 18.591 27.7621 18.548L22.3611 16.522L23.7355 9.64734C23.7666 9.48761 23.745 9.32209 23.6738 9.17576C23.6026 9.02943 23.4857 8.91022 23.3408 8.83614C23.1959 8.76205 23.0309 8.7371 22.8705 8.76505C22.7102 8.793 22.5634 8.87234 22.4521 8.99109L11.9521 20.2411C11.8668 20.3309 11.8051 20.4405 11.7725 20.5601C11.7399 20.6796 11.7374 20.8053 11.7653 20.926C11.7932 21.0467 11.8505 21.1587 11.9322 21.2518C12.0138 21.345 12.1173 21.4164 12.2333 21.4598L17.6361 23.4858L16.2655 30.353C16.2344 30.5127 16.2561 30.6782 16.3273 30.8245C16.3985 30.9709 16.5153 31.0901 16.6602 31.1642C16.8051 31.2382 16.9702 31.2632 17.1305 31.2352C17.2908 31.2073 17.4377 31.128 17.549 31.0092L28.049 19.7592C28.1327 19.6693 28.1931 19.5603 28.2248 19.4416C28.2565 19.3229 28.2586 19.1983 28.2308 19.0786ZM18.254 28.0626L19.2355 23.152C19.2706 22.9779 19.2428 22.7969 19.157 22.6413C19.0711 22.4858 18.9328 22.3658 18.7668 22.3026L13.813 20.4417L21.7461 11.9423L20.7655 16.853C20.7304 17.0271 20.7582 17.2081 20.8441 17.3636C20.9299 17.5192 21.0682 17.6392 21.2343 17.7023L26.1843 19.5586L18.254 28.0626Z"
      fill="#006CE0"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconSendAction',
  props: {
    hideBackground: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

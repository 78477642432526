<template>
  <div>
    <AppListItem :id="option.value">
      <template #figure>
        <IconFundsOnBalance v-if="option.value === PAYMENT_METHODS.FUNDS_ON_BALANCE" class="icon" />
        <IconBankTransfer v-if="option.value === PAYMENT_METHODS.BANK_TRANSFER" class="icon" />
        <IconDirectDebit v-if="option.value === PAYMENT_METHODS.DIRECT_DEBIT" class="icon" />
        <IconBolt v-if="option.value === PAYMENT_METHODS.OPEN_BANKING" class="icon" />
      </template>
      <template #left>
        <AppListItemTitle> {{ title }}</AppListItemTitle>
        <AppListItemCaption class="bank-caption">
          <div>
            <span v-html="subText" class="sub-text" />
          </div>
        </AppListItemCaption>
      </template>
    </AppListItem>
    <SendMoneyQuoteErrorMessage v-if="option.errorText" :error-text="option.errorText" />
  </div>
</template>

<script>
import { useBalancesStore, useI18nStore, useSendMoneyStore } from '@galileo/stores'
import {
  AppIcon,
  AppListItem,
  AppFigure,
  AppListItemTitle,
  AppListItemCaption,
  AppRipple,
} from '@oen.web.vue2/ui'
import { IconEdit, IconFinance } from '@oen.web.vue2/icons'
import { onMounted } from '@vue/composition-api'
import { PAYMENT_METHODS } from '@galileo/constants/sendMoneyFlow.const'
import { formatShortenCurrency } from '@galileo/composables/useCurrency'
import {
  IconBankTransfer,
  IconFundsOnBalance,
  IconDirectDebit,
} from '@galileo/assets/icons/paymentMethods'
import SendMoneyQuoteErrorMessage from '@galileo/components/Views/SendMoney/SendMoneyQuoteErrorMessage'
import IconBolt from '@galileo/assets/icons/vue/IconBolt'

export default {
  name: 'PaymentMethodOption',
  components: {
    AppIcon,
    AppListItem,
    AppFigure,
    AppListItemTitle,
    AppListItemCaption,
    IconEdit,
    IconFinance,
    AppRipple,
    IconBankTransfer,
    IconFundsOnBalance,
    IconDirectDebit,
    SendMoneyQuoteErrorMessage,
    IconBolt
  },
  props: { option: { type: Object, required: true } },
  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const balancesStore = useBalancesStore()
    const sendMoneyStore = useSendMoneyStore()

    const getAvailableBalanceOfCurrencyFrom = () => {
      const balance = balancesStore.userActiveBalanceList.find(
        (b) => b.value === sendMoneyStore.form?.currencyFrom
      )
      if (balance) return balance?.availableBalance
      return 0
    }

    const getDisplayTitle = () => {
      if (props.option.value === PAYMENT_METHODS.FUNDS_ON_BALANCE) {
        return `${props.option.title} \u2022 ${
          $t('PaymentMethodOption.AvailableBalance', {
            amount: formatShortenCurrency(getAvailableBalanceOfCurrencyFrom()),
            currency: props.option.currency,
          }).value
        }`
      } else {
        return props.option.title
      }
    }

    const getOptionSubText = () => {
      let subText = props.option.time
      return subText
    }

    return {
      $t,
      PAYMENT_METHODS,
      title: getDisplayTitle(),
      subText: getOptionSubText(),
    }
  },
}
</script>

<style scoped>
::v-deep .prime-dropdown .p-dropdown-panel .p-dropdown-item .dropdown-option {
  @apply px-3;
}
::v-deep .list-item {
  border: none;
}
::v-deep .list-item-content {
  @apply py-0;
}
::v-deep .list-item-figure {
  @apply flex;
}
::v-deep .list-item-caption {
  @apply flex flex-col justify-start gap-1;
}

.pending-verification {
  color: #b57600;
}
.xe-figure {
  @apply w-12 h-12;
  border-radius: 50%;
}
.edit-button {
  @apply p-2 rounded-tr;
}
.edit-button:hover {
  background: #e8ebed;
}

.sub-text {
  @apply text-gray-text text-sm;
  text-wrap: wrap;
}
@screen xs {
  ::v-deep .list-item-title {
    word-wrap: break-word;
    white-space: normal;
    overflow-wrap: break-word;
    overflow: hidden;
  }
}
::v-deep .icon {
  @apply bg-gray-300 rounded-full p-2 w-10 h-10;
  path {
    fill: theme('colors.grayblue.200');
  }
}
</style>

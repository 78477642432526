<template>
  <div v-if="errorMessage" class="message-container">
    <IconExclamationCircledFilled />
    <p class="message" v-html="errorMessage"></p>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import { useSendMoneyStore } from '@galileo/stores'
import IconExclamationCircledFilled from '@galileo/assets/icons/vue/IconExclamationCircledFilled.vue'

export default {
  name: 'SendMoneyQuoteErrorMessage',
  components: {
    IconExclamationCircledFilled,
  },
  props: {
    errorText: {
      type: String,
      required: false,
      default: '',
    },
  },
  setup(props) {
    const sendMoneyStore = useSendMoneyStore()

    const errorMessage = computed(() => {
      if (props.errorText) {
        return props.errorText
      }
      return sendMoneyStore.form.quoteErrorFrom
    })

    return {
      errorMessage,
    }
  },
}
</script>

<style scoped>
.message-container {
  @apply flex gap-2 items-baseline;
  ::v-deep svg {
    @apply h-3 w-3;
  }
}
.message {
  @apply my-1 text-xs font-medium;
  @apply text-xs font-medium;
  margin-top: -1.25rem;
  color: rgba(191, 37, 0, 1);
  letter-spacing: 0.015625rem;
}
::v-deep .contact-link {
  @apply underline;
}
</style>

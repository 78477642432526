<template>
  <span class="send-rate" :class="{ 'text-gray-disabled': disabled }">
    <p class="send-rate-label">{{ $t('QuickTransfer.SendRate').value }}:</p>
    <p class="send-rate-value" v-if="isSendRateShowing">
      1 {{ sendMoneyStore.getCurrencyFrom }} = {{ sendMoneyStore.form.rate }}
      {{ sendMoneyStore.form.currencyTo }}
    </p>
    <AppSkeleton class="qt-skeleton-loader" :loading="loading" height="14px" width="140px" />
  </span>
</template>

<script>
import AppSkeleton from '@oen.web.vue2/ui/src/components/AppSkeleton/AppSkeleton.vue'
import { useSendMoneyStore, useI18nStore } from '@galileo/stores'
import { computed } from '@vue/composition-api'

export default {
  name: 'QuickTransferSendRate',
  components: { AppSkeleton },
  props: {
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    loading: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  setup(props) {
    const sendMoneyStore = useSendMoneyStore()
    const { $t } = useI18nStore()

    const isSendRateShowing = computed(
      () => !props.loading && sendMoneyStore.form.rate && sendMoneyStore.form.amountFrom > 0
    )
    return {
      sendMoneyStore,
      $t,
      isSendRateShowing,
    }
  },
}
</script>

<style scoped>
::v-deep .qt-skeleton-loader .skeleton {
  @apply bg-gray-400 rounded-full;
}
.send-rate {
  @apply ml-2 text-sm  flex flex-row items-center;
  .send-rate-label {
    @apply mr-2;
  }
  .send-rate-value {
    @apply font-semibold;
  }
}
</style>

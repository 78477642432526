<template>
  <div class="no-search-results">
    <AppIcon name=" ">
      <IconSearch v-if="icon === 'search'" />
    </AppIcon>
    <p class="no-recipients-title">
      {{ title }}
    </p>
    <p class="no-recipients-text">
      {{ text }}
    </p>
  </div>
</template>

<script>
import { AppIcon } from '@oen.web.vue2/ui'
import { IconSearch } from '@oen.web.vue2/icons'

export default {
  name: 'RecipientDropdownNoResults',
  components: {
    AppIcon,
    IconSearch,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
    text: {
      type: String,
      required: false,
      default: '',
    },
    icon: {
      type: String,
      required: false,
      default: 'search',
    },
  },
}
</script>

<style scoped>
.no-search-results {
  @apply flex flex-col items-center gap-3;
  @apply text-center;
}
.no-recipients-title {
  @apply text-xs font-medium;
}
.no-recipients-text {
  @apply text-xs;
}
</style>

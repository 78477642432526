<template>
  <div>
    <slot></slot>
  </div>
</template>

<script>
// How to use this component:
// 1) Wrap this component around one or more components that you want to style
// 2) Add one of the base class names* in the style section to this components tag
// * Base class names: .qt-app-input-text, .qt-app-input-radio, qt-app-input-ammount-currency
export default {
  name: 'QTStylesWrapper',
  setup() {},
}
</script>

<style scoped>
.qt-app-input-ammount-currency {
  ::v-deep .input {
    @apply m-0;
    .input-wrapper {
      grid-template-columns: [text] auto [dropdown] 130px !important;
      .p-dropdown-items-wrapper {
        @apply w-1 top-8 border-none;
        @screen sm {
          @apply h-96;
        }
        .p-dropdown-items .dropdown-option {
          @apply flex justify-between gap-4;
          .flag {
            @apply self-center overflow-hidden w-10 rounded-full;
            height: 40px !important;
            .flag-wrapper {
              @apply left-2;
              transform: scale(2.7);
            }
          }
        }
      }
      .container-left {
        @apply bg-gray-400 rounded-l-lg;
        .input-input {
          @apply border-0 shadow-none;
        }
        .input-label {
          @apply text-gray left-3;
          z-index: 1;
        }
        .input--disabled .input-label {
          @apply text-gray-disabled;
        }
      }
      .container-right {
        .tooltip-wrapper {
          @apply flex items-center;
          .tooltip-tip {
            @apply min-w-0 text-left whitespace-normal;
          }
        }
        &.currency-expanded {
          @apply bg-white rounded-lg border-2 border-gray-light z-20;
          .currency-input .input-wrapper .input-input {
            @apply bg-transparent;
          }
        }
        .input-input {
          @apply bg-gray-400 shadow-none border-none m-0 py-2;
          .selected-value {
            @apply flex text-xl font-normal overflow-visible h-full;
            .flag {
              @apply self-center overflow-hidden w-6  rounded-full;
              height: 24px !important;
            }
            .flag-wrapper {
              @apply left-0;
              transform: scale(1.7);
            }
          }
        }
        .prime-dropdown {
          .p-dropdown-trigger {
            @apply text-gray-chevron;
          }
          .p-dropdown-label {
            @apply overflow-visible;
          }
        }
        .input--disabled .selected-value {
          @apply opacity-50;
        }
      }
      .currency-description {
        @apply flex flex-col;
      }
      .currency-text {
        @apply text-sm font-semibold leading-5;
      }
    }
  }
  ::v-deep .hide-disabled-styles .input .input-wrapper .input--disabled .selected-value {
    @apply opacity-100;
  }
}

.qt-app-input-radio {
  ::v-deep {
    .input-radio-buttons {
      @apply flex flex-row gap-2;
      .halo span {
        @apply w-4 h-4 border-text_dark-text;
      }
      label {
        @apply m-0;
      }
      .halo {
        @apply w-8 h-8 mr-0;
      }
      input[type='radio'] ~ .halo .input-radio-button:after {
        @apply bg-text_dark-text;
        width: 8px;
        height: 8px;
      }
      input[type='radio'] ~ .halo {
        &:after {
          @apply hidden;
        }
      }
      input[type='radio']:checked ~ .halo .input-radio-button {
        @apply border-text_dark-text;
      }
      .input-radio-button-label-content {
        @apply leading-4;
        .label {
          @apply font-normal;
        }
      }
      input[type='radio']:disabled ~ .input-radio-button-label-content {
        @apply text-gray-disabled;
      }
    }
    .referenceRadioOptionError {
      @apply text-red-400 font-semibold;
    }
    input[type='radio']:checked:disabled ~ .halo .input-radio-button {
      @apply border-gray-disabled;
    }
  }
}

.qt-app-input-text {
  ::v-deep {
    .input {
      @apply m-0 relative;
      &.input--error .input-input {
        @apply border-red-400;
        box-shadow: unset;
      }
      .input-input {
        @apply h-14 border-2 border-solid pt-6 pb-2 type-h2 border-transparent outline-none text-base font-normal;
        background-color: #e8ebee;
        box-shadow: unset;
        &:focus {
          @apply border-gray-light;
        }
      }
      .input-wrapper {
        @apply mt-0;
      }
    }

    .input-label {
      @apply absolute top-1 left-3 font-normal text-xs;
      z-index: 1;
    }
    .input--disabled {
      .select-placeholder {
        @apply text-gray-disabled;
      }
    }
    .select-placeholder {
      @apply text-primary-text;
    }
    .input-validation {
      @apply text-red-400 font-semibold;
    }
  }
}
.qt-app-input-dropdown {
  ::v-deep {
    .input {
      @apply m-0 relative;
      &.input--error .input-input {
        @apply border-red-400;
        box-shadow: unset;
      }
      .input-input {
        @apply h-14 type-h2 border-2 border-gray-400 outline-none text-base font-normal bg-gray-400;
        box-shadow: unset;
        &:focus {
          @apply border-gray-light;
        }
      }
      .p-dropdown-trigger {
        @apply text-grayblue-300;
      }
    }
    .input-wrapper {
      @apply mt-0;
    }

    .input-label {
      @apply absolute top-1 left-3 font-normal text-xs;
      z-index: 1;
    }
    .input--disabled {
      .select-placeholder {
        @apply text-gray-disabled;
      }
      .p-dropdown-trigger {
        @apply text-gray-disabled;
      }
    }
    .select-placeholder {
      @apply text-primary-text;
    }
    .input-validation {
      @apply text-red-400 font-semibold;
    }
  }
}
.qt-xe-input-currency {
  ::v-deep {
    .input--xs-filter-overlay {
      .input-input {
        @apply bg-transparent;
      }
      &.input {
        @apply absolute;
      }
      .input-label {
        @apply relative text-base;
      }
    }

    .input {
      @apply m-0 relative;
      .p-dropdown-filter-container input {
        @apply bg-transparent;
      }
      .p-dropdown-trigger {
        @apply -mt-3 text-grayblue-300;
      }
    }
    .input-wrapper {
      @apply mt-0;
    }
    &.input--error .input-input:focus {
      @apply border-red;
      box-shadow: unset;
    }

    .input-input {
      @apply h-14 border-2 border-solid pt-6 pb-2 type-h2 border-transparent outline-none text-base font-normal bg-gray-400;
      box-shadow: unset;
      &:focus {
        @apply border-gray-light;
      }
    }

    .input-label {
      @apply absolute top-1 left-3 font-normal text-grayblue-300 text-xs;
      z-index: 1;
    }
    .input--disabled {
      .select-placeholder {
        @apply text-gray-disabled;
      }
      .p-dropdown-trigger {
        @apply text-gray-disabled;
      }
    }
    .select-placeholder {
      @apply text-primary-text;
    }
  }
}
</style>

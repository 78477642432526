<template>
  <div class="reference-wrapper">
    <div class="toggle-wrapper">
      <AppInputToggle
        :disabled="disabled"
        v-model="paymentReferenceStore.paymentReferenceForm.includeReference.$value"
        analytics-name="payment-reference-toggle"
      />
      <p
        :class="{
          'text-gray-disabled': disabled,
        }"
      >
        {{ $t('QuickTransfer.ReferenceOptional').value }}
      </p>
    </div>
    <template v-if="paymentReferenceStore.paymentReferenceForm.includeReference.$value">
      <QTStylesWrapper class="qt-app-input-radio" :class="{ 'pointer-events-none': disabled }">
        <AppInputRadioButtons
          v-model="paymentReferenceStore.selectedReferenceOption"
          :options="paymentReferenceOptions"
        />

        <p class="referenceRadioOptionError" v-if="paymentReferenceStore.referenceOptionValidation">
          {{ paymentReferenceStore.paymentReferenceForm.referenceText.requiredText.$message }}
        </p>
      </QTStylesWrapper>

      <QTStylesWrapper
        class="qt-app-input-text"
        v-if="paymentReferenceStore.selectedReferenceOption"
      >
        <AppInputText
          v-model="paymentReferenceStore.paymentReferenceForm.referenceText.$value"
          :validation="paymentReferenceStore.paymentReferenceForm.referenceText"
          :label="paymentReferenceStore.paymentReferenceInputLabel"
          :disabled="disableReferenceTextField"
          :helper-text="$t('QuickTransfer.ReferenceInputHelperText').value"
        />
      </QTStylesWrapper>
    </template>
  </div>
</template>

<script>
import {
  useI18nStore,
  usePaymentReferenceStore,
  useSendMoneyStore,
  useQuickTransferStore,
} from '@galileo/stores'
import { computed } from '@vue/composition-api'
import {
  AppInputDropdown,
  AppInputText,
  AppInputToggle,
  AppInputRadioButtons,
} from '@oen.web.vue2/ui'
import QTStylesWrapper from '@galileo/components/Views/QuickTransfer/StyleWrapperComponents/QTStylesWrapper'

export default {
  name: 'paymentReference',
  components: {
    AppInputDropdown,
    AppInputText,
    AppInputToggle,
    AppInputRadioButtons,
    QTStylesWrapper,
  },
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props) {
    const { $t } = useI18nStore()
    const paymentReferenceStore = usePaymentReferenceStore()
    const quickTransferStore = useQuickTransferStore()

    const isProcessingTransaction = computed(() => quickTransferStore.isProcessingTransaction)

    const paymentReferenceOptions = computed(() => {
      return paymentReferenceStore.referenceOptions.map((option) => ({
        ...option,
        label: option.text,
        disabled: props.disabled,
      }))
    })

    const disableReferenceTextField = computed(
      () => paymentReferenceStore.getIsPaymentReferenceInputDisabled || props.disabled
    )

    return {
      isProcessingTransaction,
      paymentReferenceOptions,
      $t,
      paymentReferenceStore,
      disableReferenceTextField,
    }
  },
}
</script>

<style scoped>
.reference-wrapper {
  @apply flex flex-col gap-5;
}
.toggle-wrapper {
  @apply flex flex-row gap-3 ml-2;
}
.referenceRadioOptionError {
  @apply text-xs text-red-text font-medium;
}
</style>

<template>
  <div class="dropdown-title">
    <CircularFlag :currency-code="recipient.currency" class="h-6" />
    <div class="recipient-name">
      <p class="sub-title">{{ $t('ComponentRecipientSelectedOption.TitleText').value }}</p>
      <p>
        {{ recipient.fullName }}
        {{ '••' + recipient.bankAccountNumber.slice(recipient.bankAccountNumber.length - 4) }}
      </p>
    </div>
  </div>
</template>

<script>
import { useI18nStore } from '@galileo/stores'

import { getFlag } from '@galileo/composables/useFlag'
import CircularFlag from '@galileo/components/CircularFlag/CircularFlag.vue'

export default {
  name: 'RecipientSelectedOption',
  components: {
    CircularFlag,
  },
  props: {
    recipient: {
      type: Object,
      default: {},
    },
  },
  setup() {
    const { $t } = useI18nStore()

    return {
      $t,
      getFlag,
    }
  },
}
</script>

<style scoped>
.dropdown-title {
  @apply flex gap-2 items-center;
}
::v-deep .flag-wrapper {
  border-radius: 0.125rem;
  left: 0;
  transform: scale(1.7);
}
.recipient-name {
  @apply flex flex-col items-start;
  @apply text-base font-medium;
  line-height: 1.5;
  color: #313f5b;
}
.sub-title {
  @apply text-xs;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('AppInputDropdown',_vm._b({ref:"recipientDropdownRef",staticClass:"recipient-selection",class:{
      'no-recipient-selected': !_vm.activeRecipientId,
      'recipient-selector-xs': _vm.isSmallScreen,
    },attrs:{"options":_vm.recipientList,"border":false,"disabled":_vm.isProcessingTransaction || _vm.isFetchingQuote,"analytics-name":"select-recipient-dropdown","selected-display-field":"fullName","selected-value-field":"id","use-inner-filter":!_vm.isSmallScreen,"should-reset-filter-on-focus":"","filter":_vm.isSmallScreen,"placeholder":_vm.$t('ComponentRecipientSelectionDropdown.PlaceholderText').value,"filter-placeholder":_vm.$t('ComponentRecipientSelectionDropdown.FilterPlaceholderText').value,"label":_vm.isSmallScreen ? _vm.$t('ComponentXeSidebar.ButtonRecipients').value : ''},scopedSlots:_vm._u([{key:"selected",fn:function(ref){
    var option = ref.option;
return [_c('RecipientSelectedOption',{attrs:{"recipient":option}})]}},{key:"option",fn:function(ref){
    var option = ref.option;
return [_c('RecipientOption',{attrs:{"recipient":option},on:{"view-recipient":_vm.viewRecipient}})]}},{key:"no-search-results",fn:function(){return [_c('RecipientDropdownNoOptions',{attrs:{"icon":"search","title":_vm.$t('ComponentRecipientSelectionDropdown.NoRecipientTitle').value,"text":_vm.$t('ComponentRecipientSelectionDropdown.NoRecipientText').value}})]},proxy:true},{key:"no-options",fn:function(){return [_c('AppSpinnerBig',{staticClass:"spinner",attrs:{"loading":_vm.isLoadingRecipients}}),(!_vm.isLoadingRecipients)?_c('RecipientDropdownNoOptions',{attrs:{"icon":"search","title":_vm.$t('ComponentRecipientSelectionDropdown.NoAddedRecipientTitle').value,"text":_vm.$t('ComponentRecipientSelectionDropdown.NoAddedRecipientText').value}}):_vm._e()]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticClass:"footer"},[_c('button',{staticClass:"fixed-button",on:{"click":function (e) { return _vm.addRecipient(e); }}},[_c('div',{staticClass:"add-icon-div"},[_c('AppIcon',{attrs:{"name":" "}},[_c('IconAdd')],1)],1),_c('p',[_vm._v(_vm._s(_vm.$t('ComponentRecipientSelectionDropdown.AddButtonText').value))])])])]},proxy:true}]),model:{value:(_vm.activeRecipientId),callback:function ($$v) {_vm.activeRecipientId=$$v},expression:"activeRecipientId"}},'AppInputDropdown',_vm.$attrs,false)),_c('SendMoneyRecipientMissingInformationModal',{attrs:{"canCancel":"","errors":_vm.recipientValidationErrors,"missing-information":_vm.showMissingInformationModal},on:{"continue":_vm.editRecipientWithMissingInformation,"close":_vm.closeMissingInformationModalExtended}}),(_vm.showEditMissingRecipientInformation)?_c('EditMissingRecipientInformation',{key:_vm.activeRecipientId,attrs:{"recipient-validation-errors":_vm.recipientValidationErrors,"selected-recipient":_vm.selectedRecipient},on:{"reset":_vm.onResetMissingRecipientInfo,"reload-recipient":function () { return _vm.selectRecipient(_vm.activeRecipientId); }}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <AppInputDropdown
      v-model="activeRecipientId"
      ref="recipientDropdownRef"
      class="recipient-selection"
      :class="{
        'no-recipient-selected': !activeRecipientId,
        'recipient-selector-xs': isSmallScreen,
      }"
      :options="recipientList"
      :border="false"
      :disabled="isProcessingTransaction || isFetchingQuote"
      analytics-name="select-recipient-dropdown"
      v-bind="$attrs"
      selected-display-field="fullName"
      selected-value-field="id"
      :use-inner-filter="!isSmallScreen"
      should-reset-filter-on-focus
      :filter="isSmallScreen"
      :placeholder="$t('ComponentRecipientSelectionDropdown.PlaceholderText').value"
      :filter-placeholder="$t('ComponentRecipientSelectionDropdown.FilterPlaceholderText').value"
      :label="isSmallScreen ? $t('ComponentXeSidebar.ButtonRecipients').value : ''"
    >
      <template #selected="{ option }">
        <RecipientSelectedOption :recipient="option" />
      </template>
      <template #option="{ option }">
        <RecipientOption :recipient="option" @view-recipient="viewRecipient" />
      </template>
      <template #no-search-results>
        <RecipientDropdownNoOptions
          icon="search"
          :title="$t('ComponentRecipientSelectionDropdown.NoRecipientTitle').value"
          :text="$t('ComponentRecipientSelectionDropdown.NoRecipientText').value"
        />
      </template>
      <template #no-options>
        <AppSpinnerBig class="spinner" :loading="isLoadingRecipients" />
        <RecipientDropdownNoOptions
          v-if="!isLoadingRecipients"
          icon="search"
          :title="$t('ComponentRecipientSelectionDropdown.NoAddedRecipientTitle').value"
          :text="$t('ComponentRecipientSelectionDropdown.NoAddedRecipientText').value"
        />
      </template>
      <template #footer>
        <div class="footer">
          <button class="fixed-button" @click="(e) => addRecipient(e)">
            <div class="add-icon-div">
              <AppIcon name=" ">
                <IconAdd />
              </AppIcon>
            </div>
            <p>{{ $t('ComponentRecipientSelectionDropdown.AddButtonText').value }}</p>
          </button>
        </div>
      </template>
    </AppInputDropdown>
    <SendMoneyRecipientMissingInformationModal
      canCancel
      :errors="recipientValidationErrors"
      :missing-information="showMissingInformationModal"
      @continue="editRecipientWithMissingInformation"
      @close="closeMissingInformationModalExtended"
    />

    <EditMissingRecipientInformation
      v-if="showEditMissingRecipientInformation"
      :key="activeRecipientId"
      :recipient-validation-errors="recipientValidationErrors"
      :selected-recipient="selectedRecipient"
      @reset="onResetMissingRecipientInfo"
      @reload-recipient="() => selectRecipient(activeRecipientId)"
    />
  </div>
</template>

<script>
import {
  useSendMoneyStore,
  useI18nStore,
  useRecipientsStore,
  useQuickTransferStore,
} from '@galileo/stores'

import { useRouter } from '@galileo/composables/useRouter'

import { computed, ref, onBeforeMount, watch, nextTick } from '@vue/composition-api'

import RecipientOption from '@galileo/components/Views/QuickTransfer/RecipientDropdown/RecipientOption'
import RecipientSelectedOption from '@galileo/components/Views/QuickTransfer/RecipientDropdown/RecipientSelectedOption'

import { AppIcon, AppInputDropdown, AppSpinnerBig, useMediaQuery } from '@oen.web.vue2/ui'

import { IconAdd, IconSearch } from '@oen.web.vue2/icons'

import { useRecipientValidation } from '@galileo/composables/useRecipientHelpers'

import SendMoneyRecipientMissingInformationModal from '@galileo/components/Views/SendMoney/SendMoneyRecipientMissingInformationModal'
import EditMissingRecipientInformation from '@galileo/components/Views/Recipient/EditMissingRecipientInformation'
import RecipientDropdownNoOptions from '@galileo/components/Views/QuickTransfer/RecipientDropdown/RecipientDropdownNoOptions.vue'

export default {
  name: 'RecipientSeletionDropdown',
  components: {
    AppIcon,
    IconAdd,
    IconSearch,
    AppInputDropdown,
    RecipientOption,
    RecipientSelectedOption,
    AppSpinnerBig,
    /////////
    SendMoneyRecipientMissingInformationModal,
    EditMissingRecipientInformation,
    RecipientDropdownNoOptions,
  },
  props: {
    isFetchingQuote: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['select', 'validatingRecipient', 'resetFormValidation'],
  setup(props, { emit }) {
    const recipientsStore = useRecipientsStore()
    const sendMoneyStore = useSendMoneyStore()
    const quickTransferStore = useQuickTransferStore()
    const mq = useMediaQuery()
    const isSmallScreen = computed(() => mq.current === 'xs')
    const isProcessingTransaction = computed(() => quickTransferStore.isProcessingTransaction)

    const router = useRouter()
    const { $t } = useI18nStore()
    const isLoadingRecipients = ref(true)

    const recipientDropdownRef = ref(null)

    const recipientList = computed(() => {
      const currencies = sendMoneyStore.getAvailableCurrenciesTo
      const currencySet = new Set(currencies.map(ccy => ccy.value))

      return recipientsStore.recipients.filter(recipient => currencySet.has(recipient.currency))
    })

    const selectedRecipient = computed(() => {
      return recipientList.value.find((recipient) => recipient.id === activeRecipientId.value) ?? {}
    })

    onBeforeMount(async () => {
      await recipientsStore.getRecipients()

      isLoadingRecipients.value = false
    })

    let prevRecipientId = null

    let activeRecipientId = computed({
      get: () => {
        let recipientId = sendMoneyStore.form.recipient?.id
        if (!recipientId) {
          emit('resetValidation')
        } else if (prevRecipientId !== recipientId) {
          selectRecipient(recipientId)
        }
        prevRecipientId = recipientId
        return recipientId
      },
      set: async (newRecipientId) => {
        if(newRecipientId !== sendMoneyStore.form.recipient?.id) {
          prevRecipientId = newRecipientId
          selectRecipient(newRecipientId)
        }
      },
    })

    const installRecipient = (newRecipient) => {
      sendMoneyStore.form.recipient = newRecipient
      sendMoneyStore.setAvailableCurrencies(newRecipient.country)
      sendMoneyStore.form.countryTo = newRecipient.country
      sendMoneyStore.form.currencyTo = newRecipient.currency
    }

    const selectRecipient = async (newRecipientId) => {
      if (!newRecipientId) return

      const chosenRecipient = recipientList.value.find(
        (recipient) => recipient.id === newRecipientId
      )

      if (!chosenRecipient) return
      installRecipient(chosenRecipient)

      emit('validatingRecipient', true)
      quickTransferStore.isValidRecipient = await isRecipientValid(chosenRecipient)
      emit('validatingRecipient', false)
      if (quickTransferStore.isValidRecipient) {
        emit('select', chosenRecipient)
      }
    }

    const addRecipient = (e) => {
      e.preventDefault()
      unfocus()
      router.push({
        path: `add-recipient`,
        params: { shouldSetRecipient: true },
        append: true,
      })
    }
    const viewRecipient = (recipientId) => {
      unfocus()
      router.push({
        path: `recipient-details/${recipientId}`,
        append: true,
      })
    }

    const unfocus = () => {
      const primeDropDownRef = recipientDropdownRef.value.primeDropdownRef
      primeDropDownRef.hide()
    }

    //////// recipient missing information ////////////
    const {
      showMissingInformationModal,
      showEditMissingRecipientInformation,
      recipientValidationErrors,
      isRecipientValid,
      editRecipientWithMissingInformation,
      resetEditMissingInformation,
      closeMissingInformationModal,
    } = useRecipientValidation()

    const closeMissingInformationModalExtended = () => {
      sendMoneyStore.form.recipient = null
      sendMoneyStore.restoreDefaultForm()
      closeMissingInformationModal()
      emit('resetFormValidation')
    }

    const onResetMissingRecipientInfo = (shouldResetForm) => {
      resetEditMissingInformation()
      if (shouldResetForm) {
        sendMoneyStore.restoreDefaultForm()
        emit('resetFormValidation')
      }
    }

    return {
      selectRecipient,
      activeRecipientId,
      isProcessingTransaction,
      $t,
      recipientList,
      recipientsStore,
      isLoadingRecipients,
      addRecipient,
      viewRecipient,
      showMissingInformationModal,
      showEditMissingRecipientInformation,
      recipientValidationErrors,
      editRecipientWithMissingInformation,
      resetEditMissingInformation,
      closeMissingInformationModalExtended,
      onResetMissingRecipientInfo,
      selectedRecipient,
      isSmallScreen,
      recipientDropdownRef,
    }
  },
}
</script>

<style scoped>
.recipient-selection {
  z-index: 100;
  ::v-deep &.input {
    @apply mb-0;
  }
  ::v-deep &.no-recipient-selected .input-input {
    @apply border-2 border-gray-light;
  }

  ::v-deep .prime-dropdown {
    @apply h-14 bg-gray-400;

    color: #8890a0;
  }
  ::v-deep .prime-dropdown.p-inputwrapper {
    padding: 0 0.75rem;
  }
  ::v-deep .prime-dropdown.p-inputwrapper-focus {
    border-color: unset;
    box-shadow: none;
  }
  ::v-deep .p-dropdown-trigger[aria-expanded='true'] {
    color: unset;
  }
  ::v-deep .select-placeholder {
    @apply text-base w-0 flex-grow text-primary-text;
  }
  ::v-deep .prime-dropdown .p-dropdown-panel .p-dropdown-items-wrapper {
    @apply mt-3;
    max-height: unset !important;
  }
  ::v-deep .inner-search-container {
    .input-left-icon {
      @apply text-gray-dark;
    }
    .input-input {
      @apply shadow-none;
    }
  }
  ::v-deep .p-dropdown-items {
    @apply px-2 overflow-y-auto;

    @screen xs {
      height: 100%;
      padding-bottom: 100px !important;
    }
    @screen sm {
      @apply max-h-80;
      padding-bottom: 5px !important;
    }

    scrollbar-width: thin;

    .p-dropdown-item {
      @apply rounded-xl;
      .dropdown-option {
        @apply px-4;
      }
    }
  }

  .footer {
    @apply p-2;
    border-top: 1px solid #e8ebed;
    @screen xs {
      position: absolute;
      bottom: 0;
      width: 100%;
      background-color: white;
    }
    @screen md {
      position: relative;
    }
    @screen sm {
      position: relative;
    }
  }
  .fixed-button {
    @apply flex items-center justify-start gap-4 p-4 rounded-xl w-full;
  }
  .fixed-button:hover {
    background: #f7f8f9;
  }
  .add-icon-div {
    @apply flex p-2;
    width: fit-content;
    height: fit-content;
    background: #f2f3f5;
    border-radius: 50%;
    color: #636e82;
  }
  ::v-deep &.input--disabled .input-input {
    .p-inputtext {
      .select-placeholder {
        @apply text-gray-disabled;
      }
      .recipient-name {
        @apply text-gray-disabled;
      }
      .flag-wrapper {
        @apply opacity-30;
      }
    }
    .p-dropdown-trigger {
      @apply opacity-30;
    }
  }
}
@media only screen and (max-width: 600px) {
  ::v-deep .app-input-search.input--focused.input--xs {
    height: unset !important;
    position: unset;
    .input {
      padding: 0;
    }
  }
  ::v-deep .app-input-search-items-container.flex {
    display: none;
  }
}
::v-deep .list-item-action button {
  padding: 0.5rem;
  border-radius: 5px;
}
::v-deep .list-item-action button:hover {
  background: #e8ebed;
}

.recipient-selector-xs.input--xs-filter-overlay {
  ::v-deep .prime-dropdown.p-inputwrapper {
    padding: 0;
  }
}

::v-deep .input--xs-filter-overlay > .input-wrapper > .prime-dropdown {
  @apply h-14 bg-white;

  color: #8890a0;
}
.spinner {
  @apply p-12;
}
</style>



























import { defineComponent, computed, onBeforeMount, ref } from '@vue/composition-api'
import InformationAppModal, {
  ICON_TYPE,
} from '@galileo/components/InformationAppModal/InformationAppModal.vue'
import { AppButton } from '@oen.web.vue2/ui'
import { SEGMENT_EVENTS } from '@galileo/constants/segmentAnalytics'

import {
  useSendMoneyStore,
  useI18nStore,
  useAnalyticsStore,
  useQuickTransferStore,
} from '@galileo/stores'

export default defineComponent({
  name: 'RateChangeWarningModal',

  components: {
    AppButton,
    InformationAppModal,
  },

  setup() {
    const { $t } = useI18nStore()
    const sendMoneyStore = useSendMoneyStore()
    const analyticsStore = useAnalyticsStore()
    const quickTransferStore = useQuickTransferStore()

    const currencyFrom = computed(() => sendMoneyStore.form.currencyFrom)
    const currencyTo = computed(() => sendMoneyStore.form.currencyTo)
    const isUpdateQuoteLoading = ref(false)

    const rate = computed(() => sendMoneyStore.form.selectedQuote?.rate)

    const model = computed(() => quickTransferStore.shouldShowRateChangedModal)

    onBeforeMount(() => {
      analyticsStore.track({
        event: SEGMENT_EVENTS.RATE_UPDATE_MODAL_DISPLAYED,
      })
    })

    const closeAndReviewRate = async () => {
      sendMoneyStore.setConfirmedRate()
      quickTransferStore.isProcessingTransaction = false
      quickTransferStore.hideRateChangedModal()
      isUpdateQuoteLoading.value = false
    }

    return {
      $t,
      model,
      ICON_TYPE,
      rate,
      currencyFrom,
      currencyTo,
      isUpdateQuoteLoading,
      closeAndReviewRate,
    }
  },
})

<template>
  <AppInputDropdown
    v-model="activeBankAccountId"
    class="bank-selection"
    :disabled="disabled"
    :options="bankProviders"
    analytics-name="select-bank-dropdown"
    selected-display-field="accountName"
    selected-value-field="id"
    :placeholder="$t('ComponentBankSelectionDropdown.PlaceholderText').value"
    :validation="validation"
  >
    <template #selected="{ option }">
      <BankAccountSelectedOption :bank-account="option" />
    </template>
    <template #option="{ option }">
      <BankAccountOption :bank-account="option" @onDetails="onDetails" />
    </template>
    <template #no-options>
      <div class="no-accounts">
        <AppIcon name=" ">
          <IconSearch />
        </AppIcon>
        <p class="no-accounts-title">
          {{ $t('ComponentBankSelectionDropdown.NoBankAccountsTitle').value }}
        </p>
        <p class="no-accounts-text">
          {{ $t('ComponentBankSelectionDropdown.NoBankAccountsText').value }}
        </p>
      </div>
    </template>
    <template #footer>
      <div class="footer">
        <button class="fixed-button" @click="addPaymentMethod">
          <div class="add-icon-div">
            <AppIcon name=" ">
              <IconAdd />
            </AppIcon>
          </div>
          <p>{{ $t('ComponentBankSelectionDropdown.AddNewButtonText').value }}</p>
        </button>
      </div>
    </template>
  </AppInputDropdown>
</template>

<script>
import { useRouter } from '@galileo/composables/useRouter'
import { computed, ref, onBeforeMount, watch, reactive } from '@vue/composition-api'
import { usePaymentsStore, useSendMoneyStore, useI18nStore } from '@galileo/stores'
import { AppIcon, AppInputDropdown, useAlert } from '@oen.web.vue2/ui'
import { IconAdd, IconSearch } from '@oen.web.vue2/icons'
import { useAddPaymentMethods } from '@galileo/composables/usePaymentHelpers'
import BankAccountOption from '@galileo/components/Views/QuickTransfer/BankSelectionDropdown/BankAccountOption.vue'
import BankAccountSelectedOption from '@galileo/components/Views/QuickTransfer/BankSelectionDropdown/BankAccountSelectedOption.vue'
import { BANK_ACCOUNT_STATUS_ENUM } from '@galileo/constants/directDebitVerificationStatuses.const.ts'

export default {
  name: 'BankSelectionDropdown',
  components: {
    AppInputDropdown,
    AppIcon,
    IconAdd,
    IconSearch,
    BankAccountOption,
    BankAccountSelectedOption,
  },
  props: {
    disabled: { type: Boolean, required: false, default: false },
    validation: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const bankAccount = ref(null)
    const router = useRouter()
    const paymentsStore = usePaymentsStore()
    const sendMoneyStore = useSendMoneyStore()
    const { $t } = useI18nStore()

    const { add } = useAlert()

    const bankProviders = computed(() =>
      paymentsStore.paymentMethods?.bankAccounts?.filter((acc) => !acc.disabled)
    )

    const activeBankAccountId = computed({
      get: () => {
        const currencyFrom = sendMoneyStore.form.currencyFrom
        if (paymentsStore.getSelectedBankAccount?.currency !== currencyFrom) {
          bankAccount.value = null
          sendMoneyStore.form.paymentMethodId = null
        }
        if (paymentsStore.getSelectedBankAccount) {
          return paymentsStore.getSelectedBankAccount.id
        }
        return null
      },

      set: (newBankAccountId) => {
        if (newBankAccountId !== sendMoneyStore.form.paymentMethodId) {
          sendMoneyStore.form.paymentMethodId = newBankAccountId
          const selectedBankAccount = paymentsStore.getSelectedBankAccount
          if (
            selectedBankAccount.verificationStatus ===
              BANK_ACCOUNT_STATUS_ENUM.ACCOUNT_UNVERIFIED ||
            selectedBankAccount.verificationStatus === BANK_ACCOUNT_STATUS_ENUM.ACCOUNT_PENDING
          ) {
            router.push({ path: 'not-verified', append: true })
          } else if (selectedBankAccount) {
            sendMoneyStore.setPaymentMethodName({
              paymentMethodTitle: selectedBankAccount.title,
              paymentMethodCaption: selectedBankAccount.maskedAccountNumber,
              paymentMethodText: selectedBankAccount.title,
              paymentMethodNumber: selectedBankAccount.maskedAccountNumber,
            })
          }
          emit('select')
        }
      },
    })

    onBeforeMount(async () => {
      await paymentsStore.getPaymentMethods()
      if (sendMoneyStore.form.paymentMethodId) {
        bankAccount.value = sendMoneyStore.form.paymentMethodId
      }
    })

    const onDetails = (payment) => {
      paymentsStore.setSelectedPaymentMethod(payment)
      router.push({ path: 'bank-details', append: true })
    }

    const { addPaymentMethod } = useAddPaymentMethods(false)

    return {
      activeBankAccountId,
      $t,
      bankAccount,
      bankProviders,
      addPaymentMethod,
      onDetails,
    }
  },
}
</script>

<style scoped>
::v-deep .prime-dropdown {
  @apply h-14 bg-gray-400;
  color: #8890a0;
}
::v-deep .prime-dropdown.p-inputwrapper-focus {
  border-color: unset;
  box-shadow: none;
}
::v-deep .prime-dropdown.p-inputwrapper {
  padding: 0 0.75rem;
}
::v-deep .p-dropdown-trigger[aria-expanded='true'] {
  color: unset;
}
::v-deep .p-dropdown-panel .p-dropdown-items-wrapper .p-dropdown-items {
  @apply max-h-80 overflow-y-auto;
  @apply p-4;
  scrollbar-width: thin;
  .dropdown-option {
    @apply px-2;
  }
}
::v-deep .prime-dropdown .p-dropdown-panel .p-dropdown-items-wrapper {
  @apply mt-3;
  max-height: fit-content;
}
.bank-selection {
  @apply m-0;
}
.acc-currency {
  @apply py-1 px-2 rounded-tr;
  @apply font-semibold;
  background: #f2f3f5;
  color: #313f5b;
}
::v-deep .p-dropdown-item {
  @apply rounded-xl;
}
.footer {
  @apply p-2;
  border-top: 1px solid #e8ebed;
}
.fixed-button {
  @apply flex items-center justify-start gap-4 text-primary-text p-4 rounded-xl w-full text-sm font-semibold;
}
.fixed-button:hover {
  background: #f7f8f9;
}
.add-icon-div {
  @apply flex p-3;
  width: fit-content;
  height: fit-content;
  background: #f2f3f5;
  border-radius: 50%;
  color: #636e82;
}
.no-accounts {
  @apply flex flex-col items-center gap-3 p-12 text-center;
}
.no-accounts-title {
  @apply text-xs font-medium;
}
.no-accounts-text {
  @apply text-xs;
}
::v-deep li.p-dropdown-item.disabled-option {
  pointer-events: none;
  opacity: 0.4;
}
::v-deep .input-wrapper .prime-dropdown {
  @apply bg-gray-400;
}

::v-deep
  .prime-dropdown.input-input.p-dropdown.p-component.p-inputwrapper.p-disabled.p-inputwrapper-filled
  .bank-name
  p {
  @apply text-gray-disabled;
}
</style>

<template>
  <AppInputDropdown
    v-model="bankAccount"
    class="bank-selection"
    :options="bankProviders"
    :border="false"
    analytics-name="select-bank-dropdown"
    v-bind="$attrs"
    selected-display-field="accountName"
    selected-value-field="id"
    v-on="{ ...$listeners, input: () => {} }"
    :placeholder="$t('ComponentXeBankAccountSelection.PlaceholderText').value"
    @input="selectBankAccount"
    :helper-text="helperText"
    :validation="validation"
  >
    <template #selected="{ option }">
      <XeBankAccountSelectedOption :bank-account="option" />
    </template>
    <template #option="{ option }">
      <XeBankAccountOption :bank-account="option" />
    </template>
  </AppInputDropdown>
</template>

<script>
import { ref, onBeforeMount, watch, computed } from '@vue/composition-api'
import { useI18nStore, useSendMoneyStore } from '@galileo/stores'
import { AppInputDropdown } from '@oen.web.vue2/ui'
import XeBankAccountOption from '@galileo/components/Views/QuickTransfer/XeBankAccount/XeBankAccountOption.vue'
import XeBankAccountSelectedOption from '@galileo/components/Views/QuickTransfer/XeBankAccount/XeBankAccountSelectedOption.vue'
import { DELIVERY_METHODS } from '@galileo/constants/sendMoneyFlow.const.js'
export default {
  name: 'XeBankSelectionDropdown',
  emits: ['select'],
  components: {
    AppInputDropdown,
    XeBankAccountOption,
    XeBankAccountSelectedOption,
  },
  props: {
    bankProviders: {
      type: Array,
      required: true,
    },
    validation: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const sendMoneyStore = useSendMoneyStore()
    const { $t } = useI18nStore()
    const bankAccount = ref(null)

    const currencyTo = computed(() => sendMoneyStore.form.currencyTo)
    const currencyFrom = computed(() => sendMoneyStore.form.currencyFrom)

    onBeforeMount(() => {
      if (sendMoneyStore.form.paymentMethodId) {
        bankAccount.value = sendMoneyStore.form.paymentMethodId
      }
    })

    const helperText = computed(() => {
      const isFundBalance = sendMoneyStore.form.deliveryMethod === DELIVERY_METHODS.FUNDS_ON_BALANCE
      if (isFundBalance) {
        return $t('ComponentXeBankAccountSelectionFundBalance.HelperText', {
          currencyFrom: currencyFrom.value,
          currencyTo: currencyTo.value,
        }).value
      }
      return $t('ComponentXeBankAccountSelection.HelperText', {
        currencyFrom: currencyFrom.value,
        currencyTo: currencyTo.value,
      }).value
    })

    const selectBankAccount = (id) => {
      if(id !== sendMoneyStore.form.paymentMethodId) {
        emit('select', id)
      }
    }

    return {
      $t,
      selectBankAccount,
      bankAccount,
      currencyFrom,
      currencyTo,
      helperText,
    }
  },
}
</script>

<style scoped>
::v-deep .prime-dropdown {
  @apply h-14 bg-gray-200;
  color: #8890a0;
}
::v-deep .prime-dropdown.p-disabled .selected-value .dropdown-title .bank-name {
  @apply text-gray-disabled;
}
::v-deep .prime-dropdown.p-inputwrapper-focus {
  border-color: unset;
  box-shadow: none;
}
::v-deep .prime-dropdown.p-inputwrapper {
  padding: 0 0.75rem;
}
::v-deep .p-dropdown-trigger[aria-expanded='true'] {
  color: unset;
}
::v-deep .select-placeholder {
  @apply text-base;
  color: black;
}
</style>

<template>
  <AppListItem>
    <template #figure>
      <AppFigure class="xe-figure" theme="gray">
        <AppIcon name=" "><IconFinance /></AppIcon>
      </AppFigure>
    </template>
    <template #left>
      <AppListItemTitle>{{ bankAccount.name }}</AppListItemTitle>
    </template>
  </AppListItem>
</template>

<script>
import { useI18nStore, useSendMoneyStore } from '@galileo/stores'
import { AppIcon, AppListItem, AppFigure, AppListItemTitle } from '@oen.web.vue2/ui'
import { IconFinance } from '@oen.web.vue2/icons'
import { onMounted, computed } from '@vue/composition-api'
export default {
  name: 'BankAccountOption',
  emits: ['onDetails'],
  components: {
    AppIcon,
    AppListItem,
    AppFigure,
    AppListItemTitle,
    IconFinance,
  },
  props: {
    bankAccount: {
      type: Object,
      required: true,
      default: {},
    },
  },
  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const sendMoneyStore = useSendMoneyStore()

    return {
      $t,
    }
  },
}
</script>

<style scoped>
::v-deep .prime-dropdown .p-dropdown-panel .p-dropdown-item .dropdown-option {
  @apply px-3;
}
::v-deep .list-item-title {
  word-wrap: break-word;
  white-space: normal; /* Allows text to wrap */
  overflow-wrap: break-word; /* Ensures long words break to fit the container */
  overflow: hidden; /* Ensures content does not overflow the container */
}
::v-deep .list-item-content {
  @apply py-0;
}
::v-deep .list-item-figure {
  @apply flex;
}
.xe-figure {
  @apply w-12 h-12;
  border-radius: 50%;
}
</style>

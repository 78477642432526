<template>
  <div>
    <AppInputDropdown
      v-model="model"
      class="payment-method-selection"
      :disabled="disabled"
      :options="paymentMethodOptions"
      analytics-name="select-payment-method-dropdown"
      v-bind="$attrs"
      selected-display-field="label"
      selected-value-field="value"
      v-on="{ ...$listeners, input: () => {} }"
      :placeholder="$t('ComponentPaymentMethodDropdown.PlaceholderText').value"
      :validation="validation"
    >
      <template #selected="{ option }">
        <PaymentMethodSelectedOption :dropdown-disabled="disabled" :option="option" />
      </template>
      <template #option="{ option }">
        <PaymentMethodOption :option="option" />
        <AppIcon v-if="option.value === selectedPaymentMethod" name="checkmark" class="checkmark">
          <IconCheckmark />
        </AppIcon>
      </template>
    </AppInputDropdown>
    <SendMoneyQuoteErrorMessage v-if="paymentMethodErrorText" :errorText="paymentMethodErrorText" />
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import { useSendMoneyStore, useI18nStore, useAuthStore } from '@galileo/stores'
import { AppInputDropdown, useVModel, AppIcon } from '@oen.web.vue2/ui'
import { IconCheckmark } from '@oen.web.vue2/icons'
import PaymentMethodOption from '@galileo/components/Views/QuickTransfer/PaymentMethodDropdown/PaymentMethodOption.vue'
import PaymentMethodSelectedOption from '@galileo/components/Views/QuickTransfer/PaymentMethodDropdown/PaymentMethodSelectedOption.vue'
import { PAYMENT_METHODS } from '@galileo/constants/sendMoneyFlow.const'
import SendMoneyQuoteErrorMessage from '@galileo/components/Views/SendMoney/SendMoneyQuoteErrorMessage'

import { PaymentMethod } from '@galileo/models/Transaction/app'

export default {
  name: 'PaymentMethodDropdown',
  components: {
    AppInputDropdown,
    PaymentMethodOption,
    PaymentMethodSelectedOption,
    AppIcon,
    IconCheckmark,
    SendMoneyQuoteErrorMessage,
  },
  props: {
    disabled: { type: Boolean, required: false, default: false },
    value: { type: String, required: true },
    options: { type: Array, required: true },
    validation: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const authStore = useAuthStore()
    const sendMoneyStore = useSendMoneyStore()

    const userCountry = authStore.user.customer.country

    const { model } = useVModel(props, emit)

    const getOptionTitle = (option) => {
      if (option.value === PAYMENT_METHODS.FUNDS_ON_BALANCE) {
        return $t('QuickTransfer.Balance').value
      }
      if (option.value === PAYMENT_METHODS.OPEN_BANKING) {
        return $t('SendMoneySummary.VoltPaymentTextSummary').value
      }
      if (option.value === PAYMENT_METHODS.BANK_TRANSFER) {
        if (userCountry === 'US') {
          return $t('PageSendMoneyPayment.WireTransfer').value
        }
        return $t('PageSendMoneyPayment.BankTransfer').value
      }
      if (option.value === PAYMENT_METHODS.DIRECT_DEBIT) {
        if (userCountry === 'US') {
          return $t('PageActivity.PaymentMethodDirectDebitACH').value
        }
        if (userCountry === 'CA') {
          return $t('PageActivity.PaymentMethodDirectDebitEFT').value
        }
        return $t('PageActivity.PaymentMethodDirectDebit').value
      }
      return ''
    }

    const paymentMethodOptions = computed(() =>
      props.options.map((option) => {
          return { ...option, title: getOptionTitle(option) }
        })
    )

    const paymentMethodErrorText = computed(() => {
      const selectedPaymentMethod = props.options.find(
        (option) => option.value === sendMoneyStore.form.paymentMethod
      )
      if (selectedPaymentMethod) {
        return selectedPaymentMethod.errorText
      }
    })

    return {
      $t,
      model,
      paymentMethodOptions,
      paymentMethodErrorText,
      selectedPaymentMethod: computed(() => sendMoneyStore.form.paymentMethod),
    }
  },
}
</script>

<style scoped>
::v-deep .prime-dropdown {
  @apply h-14 bg-gray-400;
  color: #8890a0;
}
::v-deep .prime-dropdown.p-inputwrapper-focus {
  border-color: unset;
  box-shadow: none;
}
::v-deep .prime-dropdown.p-inputwrapper {
  padding: 0 0.75rem;
}
::v-deep .p-dropdown-trigger[aria-expanded='true'] {
  color: unset;
}
::v-deep .p-dropdown-items {
  @apply flex flex-col gap-2;
}
::v-deep .p-dropdown-panel .p-dropdown-items-wrapper .p-dropdown-items {
  @apply max-h-80 overflow-y-auto;
  @apply p-4;
  scrollbar-width: thin;
  .dropdown-option {
    @apply flex justify-between items-center;
    @apply pl-2 pr-6;
  }
}
::v-deep .prime-dropdown .p-dropdown-panel .p-dropdown-items-wrapper {
  @apply mt-3;
  max-height: fit-content;
}
.payment-method-selection {
  @apply m-0;
}

.acc-currency {
  @apply py-1 px-2 rounded-tr;
  @apply font-semibold;
  background: #f2f3f5;
  color: #313f5b;
}
::v-deep .p-dropdown-item {
  @apply rounded-xl;
}
.footer {
  @apply p-2;
  border-top: 1px solid #e8ebed;
}
.fixed-button {
  @apply flex items-center justify-start gap-4 text-primary-text p-4 rounded-xl w-full text-sm font-semibold;
}
.fixed-button:hover {
  background: #f7f8f9;
}
.add-icon-div {
  @apply flex p-3;
  width: fit-content;
  height: fit-content;
  background: #f2f3f5;
  border-radius: 50%;
  color: #636e82;
}

::v-deep .input-wrapper .prime-dropdown {
  @apply bg-gray-400;
}

::v-deep
  .prime-dropdown.input-input.p-dropdown.p-component.p-inputwrapper.p-disabled.p-inputwrapper-filled
  .bank-name
  p {
  @apply text-gray-disabled;
}

::v-deep li.p-dropdown-item.disabled-option {
  @apply pointer-events-none opacity-40;
}
</style>

import { QUOTE_SCREEN, TRANSFER } from '@galileo/constants/sendMoneyFlow.const'
import { useAuthStore, useSendMoneyStore, useApiStore } from '@galileo/stores'
import { computed, reactive, ref } from '@vue/composition-api'
import { usePromiseLazy } from 'vue-composable'
import { REQUEST_CANCELATION_TOKEN_ENUMS } from '@galileo/constants/requestCancelationTokens'

export const useFetchUpdateQuotes = () => {
  const authStore = useAuthStore()
  const sendMoneyStore = useSendMoneyStore()

  const isCorporate = authStore.isCorporateAccount

  let transferCallPromise: Promise<any> | null = null

  const fetchTransferBasicCall = async (force = false) => {
    if (isCorporate) {
      if (!transferCallPromise) {
        transferCallPromise = sendMoneyStore.sendTransfer({
          stage: TRANSFER.STAGES.BASIC,
        })
      } else {
        await transferCallPromise
      }
    }
    if (force) {
      sendMoneyStore.sendTransfer({ stage: TRANSFER.STAGES.BASIC })
    }
  }

  const calculator = reactive(
    usePromiseLazy(
      async ({
        debounceTime = 700,
        isUpdateQuote = false,
        fixedInput = QUOTE_SCREEN.AMOUNT_FROM,
      }) => {
        if (sendMoneyStore.form.countryTo && sendMoneyStore.form.currencyTo) {
          sendMoneyStore.form.quoteErrorFrom = null
          sendMoneyStore.form.shouldCalcAmountFrom = fixedInput === QUOTE_SCREEN.AMOUNT_TO
          await sendMoneyStore.calculate({
            debounceTime,
            focused: fixedInput,
            summary: isUpdateQuote,
          })
          fetchTransferBasicCall()
        }
      }
    )
  )
  const fixedAmountInput = ref(QUOTE_SCREEN.AMOUNT_FROM)

  const fetchNewQuote = async ({ fixedInput = QUOTE_SCREEN.AMOUNT_FROM, debounceTime = 700 }) => {
    if (sendMoneyStore.shouldNotCalculate) {
      sendMoneyStore.shouldNotCalculate = false
      return
    }
    useApiStore().abortApiRequest(REQUEST_CANCELATION_TOKEN_ENUMS.REFRESH_QUOTE)
    useApiStore().abortApiRequest(REQUEST_CANCELATION_TOKEN_ENUMS.CALCULATE_NEW_QUOTE)
    fixedAmountInput.value = fixedInput
    await calculator.exec({
      debounceTime,
      isUpdateQuote: false,
      fixedInput: fixedAmountInput.value,
    })
  }

  const swapCurrencies = async () => {
    const ccyTo = sendMoneyStore.form.currencyTo
    sendMoneyStore.form.currencyTo = sendMoneyStore.form.currencyFrom
    sendMoneyStore.form.currencyFrom = ccyTo

    if (sendMoneyStore.form.amountFrom === 0) return

    await calculator.exec({
      debounceTime: 0,
      isUpdateQuote: false,
      fixedInput: QUOTE_SCREEN.AMOUNT_FROM,
    })
  }

  const quickUpdateQuote = async () => {
    await calculator.exec(0)
  }

  const updatePreviousQuote = async () => {
    await calculator.exec({
      debounceTime: 0,
      isUpdateQuote: true,
      fixedInput: fixedAmountInput.value,
    })
  }

  return {
    fetchNewQuote,
    updatePreviousQuote,
    isQuoteLoading: computed(() => calculator.loading),
    quickUpdateQuote,
    swapCurrencies,
  }
}

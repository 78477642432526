<template>
  <div class="flex-wrap gap-7 flex">
    <div class="flex flex-col gap-4 flex-grow w-96">
      <RecipientSelectionDropdown
        @select="onRecipientSelected"
        @resetFormValidation="validation.$reset"
        @validatingRecipient="
          (isValidating) => {
            quickTransferStore.isProcessingTransaction = isValidating
          }
        "
        :is-fetching-quote="quickTransferStore.isCalculateQuoteLoading"
      />
      <!-- You send -->
      <QTStylesWrapper class="flex flex-col gap-4 qt-app-input-ammount-currency">
        <AppInputAmountCurrency
          v-model="form.amountFrom"
          :disabled="shouldDisableYouSendField"
          should-reset-filter-on-focus
          analytics-name="send-money-amount-from"
          :label="$t('PageSendMoneyAmount.LabelAmountFrom').value"
          :loading="amountFromLoading"
          :currency.sync="form.currencyFrom"
          :currencies="currencyDropdownOptions"
          :locale="deviceStore.getUserLocale"
          :validation="validation.amountFrom"
          expand-currency-input
          filter-check-additional-field
          additional-field-name="description"
          :filter-placeholder="$t('PageSendMoneyAmount.TypeToSearchPlaceholderText').value"
          :currency-label="$t('PageSendMoneyAmount.SelectCurrencyLabelText').value"
          filter
          force-precision
          :data-value="form.amountFrom"
        >
          <template #currency_selected="{ option }">
            <div class="flex flex-col justify-center h-full relative">
              <div class="flex flex-row">
                <CircularFlag class="mr-3" :currencyCode="option.value" />
                <p>
                  {{ option.value }}
                </p>
              </div>
            </div>
          </template>
          <template #currency_option="{ option }">
            <div class="flex items-center gap-2">
              <CircularFlag class="mr-2" :currency-code="option.value" size="40" include-border />
              <div class="currency-description">
                <span class="currency-text">{{ option.text }}</span>
                <p v-if="form.paymentMethod === PAYMENT_METHODS.FUNDS_ON_BALANCE">
                  {{
                    $t('ComponentConvertingBalances.AvailableBalanceText', {
                      amount: formatCurrencyHelper(option),
                    }).value
                  }}
                </p>
                <p v-if="form.paymentMethod !== PAYMENT_METHODS.FUNDS_ON_BALANCE">
                  {{ option.description }}
                </p>
              </div>
            </div>
            <div class="flex items-center gap-2">
              <div
                v-if="option.sameCurrencySupported && option.value === form.currencyTo"
                class="option-text"
              >
                <span class="same-currency-flag">{{
                  $t('PageQuickTransfer.SameCurrencyFlag').value
                }}</span>
              </div>
              <AppIcon name=" " v-if="option.value === form.currencyFrom">
                <IconCheckmark />
              </AppIcon>
            </div>
          </template>
        </AppInputAmountCurrency>
        <SendMoneyQuoteErrorMessage class="error-message" />
        <!-- Send rate -->
        <QuickTransferSendRate
          :loading="amountToLoading || amountFromLoading"
          :disabled="!isSelectedRecipient || isProcessingTransaction"
        />

        <!-- They get -->
        <AppInputAmountCurrency
          v-model="form.amountTo"
          should-reset-filter-on-focus
          :disabled="shouldDisableRecipientGetsField"
          :class="{
            'hide-disabled-styles': shouldHideDisabledStyles,
          }"
          disable-currency-selection
          analytics-name="send-money-amount-to"
          :label="$t('PageSendMoneyAmount.LabelAmountTo').value"
          :loading="amountToLoading"
          :currency.sync="form.currencyTo"
          :currencies="[selectedCurrencyToDropdownOption]"
          :locale="deviceStore.getUserLocale"
          :validation="validation.amountTo"
          filter
          filter-check-additional-field
          additional-field-name="description"
          :filter-placeholder="$t('PageSendMoneyAmount.TypeToSearchPlaceholderText').value"
          :currency-label="$t('PageSendMoneyAmount.SelectCurrencyLabelText').value"
          expand-currency-input
          force-precision
          :data-value="form.amountTo"
        >
          <template #currency_selected="{ option }">
            <div class="flex items-center w-full">
              <CircularFlag class="mr-3" :currency-code="option.value" />

              {{ option.value }}

              <XeTooltip side="left" class="currency-to-tooltip ml-auto" activation="hover">
                <template #wrappedElement>
                  <AppIcon name=" " class="appIcon h-fit">
                    <IconInformation class="text-gray-text" />
                  </AppIcon>
                </template>
                <template #tooltipContent>
                  <p>
                    {{ $t('QuickTransfer.RecipientGetsTooltip').value }}
                  </p>
                </template>
              </XeTooltip>
            </div>
          </template>
        </AppInputAmountCurrency>
      </QTStylesWrapper>

      <QTStylesWrapper class="qt-app-input-dropdown">
        <PaymentMethodDropdown
          v-model="form.paymentMethod"
          :options="availablePaymentMethods"
          :disabled="disablePaymentMethodSelection"
          :validation="validation.paymentMethod"
        />
      </QTStylesWrapper>

      <AppButton
        v-if="form.paymentMethod === PAYMENT_METHODS.BANK_TRANSFER"
        class="instructions-button"
        theme="secondary"
        @click="openWireTransferInstructions"
      >
        <AppIcon class="h-fit">
          <IconInformation />
        </AppIcon>
        <p>
          {{ $t('QuickTransfer.WireInstructionsButtonText', { paymentMethodTitle }).value }}
        </p>
      </AppButton>
      <QTStylesWrapper
        v-if="showXeBankProviders && form.paymentMethod === PAYMENT_METHODS.BANK_TRANSFER"
        class="qt-app-input-dropdown"
      >
        <XeBankAccountSelectionDropdown
          :bank-providers="xeBankProviders"
          @select="submitBankProvider($event)"
          :disabled="disableXeBankDropdown"
          :validation="validation.paymentMethodId"
        />
      </QTStylesWrapper>
      <QTStylesWrapper
        class="qt-app-input-dropdown"
        v-if="form.paymentMethod === PAYMENT_METHODS.DIRECT_DEBIT"
      >
        <BankSelectionDropdown
          :disabled="disableBankSelectionDropdown"
          :validation="validation.paymentMethodId"
        />
      </QTStylesWrapper>
      <ReasonForTransferSelector :is-calculating-quote="isQuoteLoading" />
      <PaymentReference :disabled="shouldDisablePaymentReference" />
    </div>
    <QuickTransferSummary
      :is-valid="isFormValid"
      :is-calculating-quote="isQuoteLoading"
      @confirm="submit"
      @on-accept-terms-click="handleAcceptTermsClick"
    />
    <PendingApprovalModal v-model="showPendingApprovalModal" />
    <WireTransferInstructionsModal
      v-if="showWireTransferWarning"
      :button-text="$t('ComponentXeTransferDetails.ButtonUnderstand').value"
      should-display-close-button
      @close="closeWireTransferInfoModal"
    />
    <RateChangeWarningModal v-if="!isBalancesEnabled && shouldShowRateChangedModal" />
    <BasicEddFieldsModal
      :open="showBasicEddModal"
      :disabled="!sendMoneyStore.isSubIndustrySelected"
      @on-subindustry-select="onSubIndustrySelect"
      @on-close="onBasicEddModalClose"
    />
    <ModalRouterView />
  </div>
</template>

<script>
import {
  AppInputAmountCurrency,
  AppDescriptionList,
  AppInputToggle,
  AppInputText,
  AppCard,
  AppIcon,
  AppInputRadioButtons,
  AppButton,
} from '@oen.web.vue2/ui'
import { computed, reactive, ref, toRef, watch, onBeforeMount } from '@vue/composition-api'
import { usePromiseLazy, useValidation } from 'vue-composable'
import PendingApprovalModal from '@galileo/components/Views/SendMoney/Amount/PendingApprovalModal.vue'
import { useRouter } from '@galileo/composables/useRouter'
import { required } from '@vuelidate/validators'
import { SendMoneyRate } from '@galileo/components/Views/SendMoney/Organisms'
import QTStylesWrapper from '@galileo/components/Views/QuickTransfer/StyleWrapperComponents/QTStylesWrapper'
import {
  QUOTE_SCREEN,
  PAYMENT_METHODS,
  DELIVERY_METHODS,
  TRANSFER_TYPE,
} from '@galileo/constants/sendMoneyFlow.const.js'
import { IconInformation, IconCheckmark } from '@oen.web.vue2/icons'
import XeTooltip from '@galileo/components/XeTooltip/XeTooltip'
import CircularFlag from '@galileo/components/CircularFlag/CircularFlag'
import ReasonForTransferSelector from '@galileo/components/Views/QuickTransfer/ReasonForTransfer/ReasonForTransferSelector'
import PaymentReference from '@galileo/components/Views/QuickTransfer/PaymentReference/PaymentReference'
import RecipientSelectionDropdown from '@galileo/components/Views/QuickTransfer/RecipientDropdown/RecipientSelectionDropdown'
import BankSelectionDropdown from '@galileo/components/Views/QuickTransfer/BankSelectionDropdown/BankSelectionDropdown'
import PaymentMethodDropdown from '@galileo/components/Views/QuickTransfer/PaymentMethodDropdown/PaymentMethodDropdown'
import {
  useAnalyticsStore,
  useAuthStore,
  useDeviceStore,
  usePaymentsStore,
  useSendMoneyStore,
  useI18nStore,
  usePaymentReferenceStore,
  useRecipientsStore,
  useQuickTransferStore,
  useAppStore,
  useBalancesStore,
  useKYCRefreshCorporateStore,
} from '@galileo/stores'
import {
  SEGMENT_EVENTS,
  SEGMENT_LOCATIONS,
  SEGMENT_PAYMENT_METHOD_TYPES,
} from '@galileo/constants/segmentAnalytics'
import XeBankAccountSelectionDropdown from '@galileo/components/Views/QuickTransfer/XeBankAccount/XeBankAccountSelectionDropdown.vue'
import QuickTransferSummary from '@galileo/components/Views/QuickTransfer/QuickTransferSummary/QuickTransferSummary.vue'
import { ACCOUNT_KYC_STATUS } from '@galileo/constants/accountKycStatus.const.ts'
import SendMoneyQuoteErrorMessage from '@galileo/components/Views/SendMoney/SendMoneyQuoteErrorMessage'
import { getFlag } from '@galileo/composables/useFlag'
import WireTransferInstructionsModal from '@galileo/components/Views/SendMoney/Modals/WireTransferInstructionsModal'
import { ACCOUNT_TYPE } from '@galileo/constants/accountType.const'
import QuickTransferSendRate from '@galileo/components/Views/QuickTransfer/QuickTransferSendRate/QuickTransferSendRate'
import { formatCurrency } from '@galileo/composables/useCurrency'
import ModalRouterView from '@galileo/views/ModalRouterView'
import { useFetchUpdateQuotes } from '@galileo/composables/useFetchUpdateQuote'
import RateChangeWarningModal from '@galileo/components/Views/QuickTransfer/Modals/RateChangeWarningModal'
import BasicEddFieldsModal from '@galileo/components/Views/QuickTransfer/Modals/BasicEddFields/ui/BasicEddFieldsModal.vue'
import { useBasicEddDialog } from '@galileo/components/Views/QuickTransfer/Modals/BasicEddFields/hooks/use-basic-edd-modal'
import { isEmpty } from 'lodash-es'

export default {
  name: 'QuickTransfer',
  components: {
    BasicEddFieldsModal,
    CircularFlag,
    QuickTransferSendRate,
    PaymentReference,
    AppInputAmountCurrency,
    AppDescriptionList,
    AppInputToggle,
    AppInputText,
    SendMoneyRate,
    AppCard,
    IconInformation,
    AppIcon,
    XeTooltip,
    AppInputRadioButtons,
    ReasonForTransferSelector,
    RecipientSelectionDropdown,
    BankSelectionDropdown,
    PaymentMethodDropdown,
    IconCheckmark,
    AppButton,
    XeBankAccountSelectionDropdown,
    QuickTransferSummary,
    QTStylesWrapper,
    PendingApprovalModal,
    SendMoneyQuoteErrorMessage,
    WireTransferInstructionsModal,
    ModalRouterView,
    RateChangeWarningModal,
  },
  setup() {
    const sendMoneyStore = useSendMoneyStore()
    const deviceStore = useDeviceStore()
    const authStore = useAuthStore()
    const paymentsStore = usePaymentsStore()
    const { $t } = useI18nStore()
    const router = useRouter()
    const analyticsStore = useAnalyticsStore()
    const recipientsStore = useRecipientsStore()
    const paymentReferenceStore = usePaymentReferenceStore()
    const quickTransferStore = useQuickTransferStore()
    const appStore = useAppStore()
    const balancesStore = useBalancesStore()
    const kycRefreshCorporateStore = useKYCRefreshCorporateStore()

    const showPendingApprovalModal = ref(false)
    const isSelectedRecipient = computed(() => sendMoneyStore.form.recipient?.id)
    const isSameCurrencySupported = authStore.userProfile.sameCurrencySupported
    const sameCurrencyFromTradingText = ref('')
    const currencyToFocus = ref(false)
    const isCorporate = authStore.isCorporateAccount
    const showWireTransferWarning = ref(false)

    const availablePaymentMethods = computed(() => {
      if (sendMoneyStore.form.availableQuotes === null) {
        return []
      }

      const quickTransferQuotes = sendMoneyStore.form.availableQuotes.filter(
        (quote) => quote.deliveryMethod === DELIVERY_METHODS.BANK_ACCOUNT
      )

      return quickTransferQuotes.map((quote) => ({
        ...quote,
        value: quote.paymentMethod,
        currency: form.currencyFrom,
      }))
    })

    const shouldDisablePaymentReference = computed(
      () => !isSelectedRecipient.value || isProcessingTransaction.value || isQuoteLoading.value
    )

    const shouldDisableYouSendField = computed(
      () => !isSelectedRecipient.value || isProcessingTransaction.value || amountFromLoading.value
    )

    const shouldDisableRecipientGetsField = computed(
      () => !isSelectedRecipient.value || isProcessingTransaction.value || amountToLoading.value
    )

    const shouldHideDisabledStyles = computed(
      () => isSelectedRecipient.value && !isProcessingTransaction.value && !amountToLoading.value
    )

    const isProcessingTransaction = computed(() => quickTransferStore.isProcessingTransaction)

    const disablePaymentMethodSelection = computed(
      () =>
        isProcessingTransaction.value ||
        availablePaymentMethods.value.length === 0 ||
        isQuoteLoading.value
    )

    const disableXeBankDropdown = computed(
      () =>
        isProcessingTransaction.value ||
        !isSelectedRecipient.value ||
        quickTransferStore.isCalculateQuoteLoading ||
        isQuoteLoading.value
    )

    const disableBankSelectionDropdown = computed(
      () =>
        isProcessingTransaction.value ||
        !isSelectedRecipient.value ||
        availablePaymentMethods.value.length === 0 ||
        isQuoteLoading.value
    )

    const PROPERTY_CHANGED = {
      get DELIVERY_METHOD() {
        PROPERTY_CHANGED._deliveryMethodChanges++
        return {
          counter: PROPERTY_CHANGED._deliveryMethodChanges,
          name: 'delivery method',
        }
      },

      get PAYMENT_METHOD() {
        PROPERTY_CHANGED._paymentMethodChanges++
        return {
          counter: PROPERTY_CHANGED._paymentMethodChanges,
          name: 'payment method',
        }
      },

      _deliveryMethodChanges: 0,
      _paymentMethodChanges: 0,
    }

    const currenciesFrom = computed(() => {
      const currencies = sendMoneyStore.getCombinedAvailableCurrenciesFrom.filter(
        (currency) => currency.value !== form.currencyTo
      )

      const selectedCurrency = sendMoneyStore.getAvailableCurrenciesFrom.find(
        (currency) => currency.value === form.currencyTo
      )

      if (selectedCurrency?.sameCurrencySupported && isSameCurrencySupported) {
        const currency = selectedCurrency.value
        sameCurrencyFromTradingText.value = $t('PageSendMoneyAmount.DropdownSameOptionFromLable', {
          currency,
        }).value
        const currencyList = currencies.filter((currency) => currency.value !== form.currencyTo)
        currencyList.unshift(selectedCurrency)
        return currencyList
      }

      return currencies
    })

    const currencyDropdownOptions = computed(() => {
      if (form.paymentMethod === PAYMENT_METHODS.FUNDS_ON_BALANCE) {
        return balancesStore.userActiveBalanceList
      } else {
        return currenciesFrom.value
      }
    })

    const amountFromLoading = computed(() => {
      return form.amountTo > 0 && isQuoteLoading.value && sendMoneyStore.form.shouldCalcAmountFrom
    })

    const amountToLoading = computed(
      () => form.amountFrom > 0 && isQuoteLoading.value && !sendMoneyStore.form.shouldCalcAmountFrom
    )

    const xeBankProviders = ref([])

    const shouldShowRate = computed(() => !validation.$anyInvalid || validation.$anyDirty)

    const { fetchNewQuote, updatePreviousQuote, isQuoteLoading } = useFetchUpdateQuotes()

    const currencyFromChanged = ref(false)

    const form = reactive({
      amountFrom: computed({
        get: () => sendMoneyStore.getAmountFrom(false),
        set: async (amount) => {
          sendMoneyStore.form.amountFrom = amount
          //reset quoteError
          sendMoneyStore.form.quoteErrorFrom = null
          await fetchNewQuote({ fixedInput: QUOTE_SCREEN.AMOUNT_FROM })
          updateBankTransferId()
        },
      }),
      currencyFrom: computed({
        get: () => sendMoneyStore.getCurrencyFrom,
        set: async (currency) => {
          if (currency !== sendMoneyStore.form.currencyFrom) {
            currencyToFocus.value = false
            currencyFromChanged.value = true
            sendMoneyStore.form.currencyFrom = currency
            xeBankProviders.value = await paymentsStore.getBankProviders(form.currencyFrom)

            await fetchNewQuote({ fixedInput: QUOTE_SCREEN.AMOUNT_FROM, debounceTime: 0 })
            updateBankTransferId()
            currencyFromChanged.value = false
          }
        },
      }),
      amountTo: computed({
        get: () => sendMoneyStore.getAmountTo(false),
        set: async (amount) => {
          sendMoneyStore.form.amountTo = amount
          await fetchNewQuote({ fixedInput: QUOTE_SCREEN.AMOUNT_TO })
        },
      }),
      currencyTo: computed({
        get: () => sendMoneyStore.form.currencyTo,
        set: async (currency) => {
          currencyToFocus.value = true

          if (
            currency === form.currencyFrom &&
            (!isSameCurrencySupported ||
              (isSameCurrencySupported && !isSameCurrencyAvailable.value))
          ) {
            //switch
            const currencyFromSwitched = sendMoneyStore.getFirstFavoriteCurrency(
              form.currencyFrom
            ).value
            sendMoneyStore.form.currencyFrom = currencyFromSwitched
          }

          sendMoneyStore.form.currencyTo = currency

          if (!sendMoneyStore.form.shouldCalcAmountFrom) {
            sendMoneyStore.form.amountTo = null
          }
          await fetchNewQuote({ fixedInput: QUOTE_SCREEN.AMOUNT_TO, debounceTime: 0 })
        },
      }),
      paymentMethod: computed({
        get: () => sendMoneyStore.form.paymentMethod,
        set: async (method) => {
          if (method === sendMoneyStore.form.paymentMethod) {
            return
          }
          // no calculate needed since we already have the quotes
          sendMoneyStore.setPaymentMethodAction(method)

          const selectedPaymentMethod = availablePaymentMethods.value.find(
            (paymentMethod) => paymentMethod.value === method
          )

          sendMoneyStore.setPaymentMethodType(selectedPaymentMethod)
          updateBankTransferId()
          if (method === PAYMENT_METHODS.FUNDS_ON_BALANCE) {
            await balancesStore.fetchUserActiveBalanceList()
          }
          sendMoneyStore.setConfirmedRate()
        },
      }),
    })

    const isCurrencyAccountAvailable = computed(() => {
      const isCurrencyAvailable = balancesStore.userActiveBalanceList.find(
        (ccy) => ccy.value === form.currencyFrom
      )
      return form.paymentMethod === PAYMENT_METHODS.FUNDS_ON_BALANCE && !!isCurrencyAvailable
    })

    onBeforeMount(async () => {
      if (
        !appStore.isFromIncompleteTransaction &&
        (!sendMoneyStore.form.sendAgain || !isCurrencyAccountAvailable.value)
      ) {
        sendMoneyStore.restoreDefaultForm()
        sendMoneyStore.form.deliveryMethod = DELIVERY_METHODS.BANK_ACCOUNT
        sendMoneyStore.form.transferType = TRANSFER_TYPE.QUICK_TRANSFER
        validation.$reset()
        validation.amountFrom.$reset()
        quickTransferStore.isProcessingTransaction = false

        if (authStore.userProfile.country === 'US') {
          form.currencyTo = 'EUR'
        } else {
          form.currencyTo = 'USD'
        }
      } else {
        const paymentReferenceForm = paymentReferenceStore.paymentReferenceForm
        await onRecipientSelected(sendMoneyStore.form.recipient)
        paymentReferenceForm.reasonForTransferOption.$value = sendMoneyStore.form.transferReason
        paymentReferenceForm.reasonForTransferText.$value = sendMoneyStore.form.transferReasonText
        updateBankTransferId()
      }
      if (appStore.currentRoute.name === 'QuickTransfer') {
        analyticsStore.track({
          event: SEGMENT_EVENTS.QUICK_TRANSFER_ACCESSED,
          traits: {
            location: SEGMENT_LOCATIONS.QUICK_TRANSFER,
            accountType: isCorporate ? ACCOUNT_TYPE.CORPORATE : ACCOUNT_TYPE.CONSUMER,
          },
        })
      }
    })

    const defaultSendAmount = sendMoneyStore.getDefaultAmountFrom()

    const onRecipientSelected = async (recipient) => {
      const favoriteCurrency = sendMoneyStore.getFirstFavoriteCurrency(form.currencyFrom)
      sendMoneyStore.form.currencyTo = recipient.currency
      sendMoneyStore.form.countryTo = recipient.country
      sendMoneyStore.form.amountFrom =
        sendMoneyStore.form.amountFrom === 0 ? defaultSendAmount : sendMoneyStore.form.amountFrom // this needs improvement!!!!
      quickTransferStore.isCurrentTransactionQuickTransfer = true
      paymentsStore
        .getBankProviders(form.currencyFrom)
        .then((result) => (xeBankProviders.value = result))
      await Promise.all([
        balancesStore.fetchUserActiveBalanceList(),
        fetchNewQuote({ debounceTime: 0, fixedInput: QUOTE_SCREEN.AMOUNT_FROM }),
      ])
      if (!isSameCurrencySupported && form.currencyFrom === form.currencyTo) {
        sendMoneyStore.form.currencyFrom = favoriteCurrency.value
      }
      updateBankTransferId()
    }

    watch(
      () => form.paymentMethod,
      (newVal, oldVal) => {
        if (oldVal) {
          analyticsStore.track({
            event: SEGMENT_EVENTS.QUOTE_PAYMENT_METHOD_CHANGED,
            traits: {
              old_method: oldVal,
              new_method: newVal,
              display_methods: sendMoneyStore.form.paymentMethods
                .filter((paymentMethod) => paymentMethod.isAvailable)
                .map((x) => x.value),
            },
          })
        }
      }
    )

    const showXeBankProviders = ref(false)

    const paymentMethodTitle =
      authStore.userProfile.country === 'US'
        ? $t('PageSendMoneyPayment.WireTransfer').value
        : $t('PageSendMoneyPayment.BankTransfer').value

    const submitBankProvider = async (providerId) => {
      const selectedXeBankProvider = xeBankProviders.value.find((bank) => bank.id === providerId)
      sendMoneyStore.form.paymentMethodId = selectedXeBankProvider.id

      sendMoneyStore.setPaymentMethodName({
        paymentMethodTitle: paymentMethodTitle,
        paymentMethodCaption: selectedXeBankProvider.bankName,
        paymentMethodText: selectedXeBankProvider.bankName,
      })
      analyticsStore.track({
        event: SEGMENT_EVENTS.PAYMENT_METHOD_SELECTED,
        traits: {
          paymentType: SEGMENT_PAYMENT_METHOD_TYPES.STAGED,
        },
      })
    }

    const updateBankTransferId = () => {
      if (form.paymentMethod === PAYMENT_METHODS.BANK_TRANSFER) {
        if (xeBankProviders.value?.length === 1) {
          if (xeBankProviders.value[0].id !== sendMoneyStore.form.paymentMethodId) {
            showXeBankProviders.value = false
            const onlyAvailableBank = xeBankProviders.value[0].id
            submitBankProvider(onlyAvailableBank) // case when there is only one xe bank provider!!!
          }
        } else {
          sendMoneyStore.form.paymentMethodId = null
          showXeBankProviders.value = true
        }
      }
      validation.paymentMethodId.$reset()
    }

    const availableCurrencies = computed(() => sendMoneyStore.getAvailableCurrenciesTo)

    const isSameCurrencyAvailable = computed(() => {
      let pivot
      if (currencyToFocus.value) {
        pivot = form.currencyFrom
      } else {
        pivot = form.currencyTo
      }
      if (availableCurrencies.value?.length) {
        let selectedCurrency = availableCurrencies.value.find(
          (currency) => currency.value === pivot
        )
        return selectedCurrency?.sameCurrencySupported
      }
      return false
    })

    const validation = useValidation({
      quoteId: {
        $value: computed(() => sendMoneyStore.form.quoteId),
      },
      amountFrom: {
        $value: toRef(form, 'amountFrom'),
        isInvalid: {
          $validator() {
            return !sendMoneyStore.form.quoteErrorFrom
          },
          //use custom component in order to display the message / workaround to invalidate the field
          $message: ' ',
        },
        required: {
          $validator(v) {
            return sendMoneyStore.form.shouldCalcAmountFrom || form.amountFrom
          },
          $message: $t('PageSendMoneyAmount.AmountFromRequired').value,
        },
      },
      currencyFrom: {
        $value: toRef(form, 'currencyFrom'),
        required,
      },
      amountTo: {
        $value: toRef(form, 'amountTo'),
        required: {
          $validator(v) {
            return !(sendMoneyStore.form.shouldCalcAmountFrom && !form.amountTo)
          },
          $message: $t('PageSendMoneyAmount.AmountToRequired').value,
        },
      },
      currencyTo: {
        $value: toRef(form, 'currencyTo'),
        required,
      },
      paymentMethod: {
        $value: toRef(form, 'paymentMethod'),
        isInvalid: {
          $validator(v) {
            const selectedPaymentMethod = availablePaymentMethods.value.find(
              (method) => method.value === v
            )
            return !selectedPaymentMethod?.errorText
          },
          $message: ' ',
        },
      },
      paymentMethodId: {
        // We want to make sure that paymentmethodId has been set before we subimt transfer
        $value: computed(() => sendMoneyStore.form.paymentMethodId),
        // we should remove this once we get the API for payment method ID
        required: {
          $validator: (value) => {
            if (
              form.paymentMethod === PAYMENT_METHODS.FUNDS_ON_BALANCE ||
              form.paymentMethod === PAYMENT_METHODS.OPEN_BANKING
            ) {
              return true
            }

            if (currencyFromChanged.value) {
              return true
            }

            return !!value
          },
          $message: $t('QuickTransfer.BankAccountRequired').value,
        },
      },
    })

    const selectedCurrencyToDropdownOption = computed(() => {
      let result = sendMoneyStore.getCombinedAvailableCurrenciesTo.find(
        (c) => c.value === form.currencyTo
      )
      if (!result) {
        result = []
      }
      return result
    })

    const formatCurrencyHelper = (option) => {
      return formatCurrency(option.value, option.availableBalance, deviceStore.locale)
    }

    let accountIsRestricted = sendMoneyStore.accountIsRestricted || authStore.userProfile.customer.accountRestricted

    const isAccountPendingVerification = computed(() => {
      return sendMoneyStore.isVerificationPending
    })

    const processOrder = reactive(
      usePromiseLazy(() => sendMoneyStore.processOrder())
    )
    const isAccountNotApproved =
      authStore.userProfile.customer.accountKycStatus === ACCOUNT_KYC_STATUS.NOT_APPROVED

    const resetHasFormChanged = () => {
      validation.$reset()
      paymentReferenceStore.paymentReferenceForm.$reset()
    }

    const hasFormChanged = () =>
      paymentReferenceStore.paymentReferenceForm.$anyDirty || validation.$anyDirty

    const revealValidationErrors = () => {
      validation.$touch()
      paymentReferenceStore.paymentReferenceForm.$touch()
    }

    const isFormValid = computed(
      () => !validation.$anyInvalid && !paymentReferenceStore.paymentReferenceForm.$anyInvalid
    )

    const {
      showBasicEddModal,
      basicEddErrors,
      onBasicEddModalClose,
    } = useBasicEddDialog()

    const handleAcceptTermsClick = async (isAccepted) => {
      if (isFormValid.value) {
        paymentReferenceStore.updateSMFormWithPaymentReference()
        if (hasFormChanged() && isAccepted) {
          sendMoneyStore.setConfirmedRate()

          // avoid opening basic EDD modal if form is already filled
          if (isEmpty(sendMoneyStore.form.filledAdditionalFields)) {
            basicEddErrors.value = sendMoneyStore.transferBasicResponse?.errors ?? []
          }

          await updatePreviousQuote()
          resetHasFormChanged()
        }
      } else {
        revealValidationErrors()
      }
    }

    const onSubIndustrySelect = (subIndustryId) => {
      sendMoneyStore.form.filledAdditionalFields = {
        ...sendMoneyStore.form.filledAdditionalFields,
        corpSubIndustry: subIndustryId,
      }
    }

    const checkAndHandleRateChange = () => {
      if (sendMoneyStore.didRateChange) {
        quickTransferStore.showRateChangedModal()
        sendMoneyStore.setConfirmedRate()
        return true // return true if the rate change was handled
      } else {
        return false
      }
    }

    const doesQuoteNeedUpdate = computed(
      () =>
        validation.quoteId.$dirty ||
        validation.paymentMethod.$dirty ||
        validation.paymentMethodId.$dirty ||
        paymentReferenceStore.paymentReferenceForm.$anyDirty
    )

    const submit = async () => {
      if (authStore.isCorporateAccount && kycRefreshCorporateStore.isOverdue) {
        kycRefreshCorporateStore.showModal({ isBlockingTransaction: true })
        return
      }
      analyticsStore.track({
        event: SEGMENT_EVENTS.QUICK_TRANSFER_COMPLETED,
        traits: {
          location: SEGMENT_LOCATIONS.QUICK_TRANSFER,
          accountType: isCorporate ? ACCOUNT_TYPE.CORPORATE : ACCOUNT_TYPE.CONSUMER,
          tbuId: authStore.user.customer.businessUnitId,
          tbuRegion: authStore.user.customer.region,
        },
      })

      paymentReferenceStore.updateSMFormWithPaymentReference()
      showPendingApprovalModal.value = isAccountNotApproved

      if (showPendingApprovalModal.value) {
        return
      }
      
      if(accountIsRestricted) {
        router.push({ path: 'account-restricted', append: true })
        return
      }

      quickTransferStore.isProcessingTransaction = true

      // Checking if we should update the quote. We always do the update on first submit.
      // If the rate change, on second submit we are doing the update only if the user changes recipient(quoteId), payment reference or reason for transfer
      if (doesQuoteNeedUpdate.value) {
        await updatePreviousQuote()
        resetHasFormChanged()
      } else {
        sendMoneyStore.setConfirmedRate()
      }

      if (checkAndHandleRateChange()) {
        return
      }

      //we stop refreshing the quote from now on.
      sendMoneyStore.clearRefreshQuote()
      sendMoneyStore.setConfirmedRate()

      sendMoneyStore.transactionCreatedSegmentEvent(SEGMENT_EVENTS.REVIEW_TRANSFER)

      await processOrder.exec()

      if (processOrder.error) {
        // TODO: Implement this logic for consumers
        if (!accountIsRestricted && !isAccountPendingVerification.value) {
          router.replace(processOrder.error)
        }
      } else {
        router.replace(processOrder.result)
      }
    }

    const openWireTransferInstructions = () => {
      showWireTransferWarning.value = true
    }

    const closeWireTransferInfoModal = () => {
      showWireTransferWarning.value = false
    }

    return {
      showBasicEddModal,
      updatePreviousQuote,
      formatCurrency,
      disableBankSelectionDropdown,
      currencyDropdownOptions,
      isFormValid,
      quickTransferStore,
      isProcessingTransaction,
      selectedCurrencyToDropdownOption,
      recipientsStore,
      form,
      showPendingApprovalModal,
      $t,
      validation,
      sendMoneyStore,
      deviceStore,
      currenciesFrom,
      getFlag,
      amountFromLoading,
      sameCurrencyFromTradingText,
      amountToLoading,
      shouldShowRate,
      onRecipientSelected,
      submit,
      xeBankProviders,
      showXeBankProviders,
      submitBankProvider,
      isSelectedRecipient,
      PAYMENT_METHODS,
      availablePaymentMethods,
      showWireTransferWarning,
      openWireTransferInstructions,
      closeWireTransferInfoModal,
      paymentMethodTitle,
      disablePaymentMethodSelection,
      disableXeBankDropdown,
      shouldDisablePaymentReference,
      shouldDisableYouSendField,
      shouldDisableRecipientGetsField,
      shouldHideDisabledStyles,
      updateBankTransferId,
      isQuoteLoading,
      formatCurrencyHelper,
      isBalancesEnabled: computed(() => authStore.getIsBalancesEnabled),
      handleAcceptTermsClick,
      shouldShowRateChangedModal: computed(() => quickTransferStore.shouldShowRateChangedModal),
      onSubIndustrySelect,
      onBasicEddModalClose,
    }
  },
}
</script>

<style scoped>
.same-currency-flag {
  @apply p-2 rounded-md;
  @apply text-xs font-semibold;
  background: rgba(218, 232, 245, 1);
  color: rgba(0, 86, 179, 1);
}

/* end */
.reference-input-text {
  @apply m-0;

  ::v-deep {
    .input-wrapper {
      @apply mt-0;
    }

    .input-input {
      @apply h-14 border-2 border-solid pt-8 pb-2 type-h2 border-transparent outline-none text-base font-normal;
      background-color: #e8ebee;
      box-shadow: unset;

      &:focus {
        @apply border-gray-light;
      }
    }

    .input-label {
      @apply relative;
      top: 1.75rem;
      left: 0.75rem;
      @apply font-normal text-xs;
      z-index: 1;
    }
  }
}

::v-deep .reference-input-text.input--error .input-input:focus {
  @apply border-red;
  box-shadow: unset;
}

.instructions-button {
  @apply border border-gray-light rounded-lg bg-transparent !important;
  ::v-deep .button-inner.ripple.md-ripple {
    @apply flex justify-start gap-3 text-sm;
  }
}

.instructions-button:hover {
  @apply bg-white !important;
}

::v-deep .tooltip-wrapper.currency-to-tooltip .tooltip-tip {
  @apply bg-grayblue-400;

  &::before {
    border-left-color: theme('colors.grayblue.400');
  }
}

.disabled-radio-btns {
  @apply pointer-events-none opacity-50;
}
.error-message {
  @apply -mt-4;
}
</style>

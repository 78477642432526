import { KycError } from "@galileo/api/launchpad/transfer/kyc-error";
import { computed, ref } from "@vue/composition-api";

export const useBasicEddDialog = () => {
  const basicEddErrors = ref<KycError[]>([])

  const showBasicEddModal = computed(
    () =>
      basicEddErrors.value.some(
        (error) =>
          error.id === "corpIndustry" || error.id === "corpSubIndustry"
      )
  );

  const onBasicEddModalClose = () => {
    basicEddErrors.value = []
  }

  return { basicEddErrors, showBasicEddModal, onBasicEddModalClose }
}

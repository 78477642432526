<template>
  <div class="quick-transfer-summary-wrapper">
    <div v-if="noQuoteDetails" class="summary-no-data">
      <IconSendAction />
      <h1 class="no-data-title">
        {{ $t('ComponentQuickTransferSummary.NoContentTitle').value }}
      </h1>
      <p class="no-data-text">
        {{ $t('ComponentQuickTransferSummary.NoContentText').value }}
      </p>
    </div>
    <div v-else class="relative">
      <h1 class="quote-details-title">
        {{ $t('ComponentQuickTransferSummary.SectionTitle').value }}
      </h1>
      <CardWarning v-if="shouldShowRateAlert && isAcceptedTermsAndConditions">
        <template #text>
          {{ $t('ComponentQuickTransferSummary.RateChangeWarningText').value }}
        </template>
      </CardWarning>
      <AppSpinnerBig class="spinner" :loading="isQuoteDataInvalid && isValidRecipient" />
      <div v-if="form && form.selectedQuote" class="my-8">
        <QuickTransferSummaryItem
          icon="clock"
          :title="$t('ComponentQuickTransferSummary.ItemShouldArriveText').value"
          :value="form.selectedQuote.time ? form.selectedQuote.time : ''"
        />
        <QuickTransferSummaryItem
          icon="coins"
          :title="$t('ComponentQuickTransferSummary.ItemTransferFeesText').value"
          :value="`${addPrecision(form.selectedQuote.fee)} ${form.currencyFrom}`"
        />
        <div class="total-to-pay">
          <p class="total-to-pay-text">
            {{ $t('ComponentConvertBalanceSummary.TotalToPayText').value }}
          </p>
          <p v-if="!isCalculatingQuote" class="total-to-pay-amount">
            {{
              `${formatCurrency(form.currencyFrom, form.selectedQuote.totalAmount)} ${
                form.currencyFrom
              }`
            }}
          </p>
          <AppSkeleton
            class="qt-skeleton-loader"
            :loading="true"
            v-if="isCalculatingQuote"
            height="14px"
            width="100px"
          />
        </div>
      </div>
    </div>
    <AppInputCheckbox
      v-if="recipient"
      data-testid="qt-i-agree-chkbox"
      :value="isAcceptedTermsAndConditions"
      @input="onCheck"
      class="terms-and-conditions-checkbox"
      :class="{ loading: isProcessingTransaction || isQuoteDataInvalid || isCalculatingQuote }"
    >
      <span
        v-html="
          $t('ComponentTermsAndConditionsModal.TextIAgree', {
            termsAndConditionsURL: termsAndConditionsURL,
          }).value
        "
      ></span>
    </AppInputCheckbox>
    <AppButton
      v-if="recipient"
      data-testid="qt-confirm-and-send-btn"
      :disabled="disableConfirmButton"
      :loading="isProcessingTransaction"
      @click="$emit('confirm')"
    >
      {{ $t('ComponentQuickTransferSummary.ButtonConfirmText').value }}
    </AppButton>
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'

import {
  useI18nStore,
  useResourcesStore,
  useSendMoneyStore,
  useQuickTransferStore,
} from '@galileo/stores'

import {
  AppCard,
  AppIcon,
  AppDescriptionListItem,
  AppButton,
  AppCardHeader,
  AppInputCheckbox,
  AppSpinnerBig,
} from '@oen.web.vue2/ui'
import { IconSendAction } from '@galileo/assets/icons/quickTransferActions'
import { IconSmallClock, IconCoins, IconReceipt } from '@galileo/assets/icons/vue'
import CardWarning from '@galileo/components/CardWarning/CardWarning'
import QuickTransferSummaryItem from '@galileo/components/Views/QuickTransfer/QuickTransferSummary/QuickTransferSummaryItem.vue'
import { addPrecision } from '@galileo/utilities/filters'
import { formatCurrency } from '@galileo/composables/useCurrency'
import AppSkeleton from '@oen.web.vue2/ui/src/components/AppSkeleton/AppSkeleton.vue'

export default {
  name: 'QuickTransfer',
  emits: ['confirm', 'on-accept-terms', 'on-accept-terms-click'],
  components: {
    AppCard,
    AppButton,
    AppIcon,
    IconSmallClock,
    AppDescriptionListItem,
    IconCoins,
    IconReceipt,
    AppCardHeader,
    AppInputCheckbox,
    CardWarning,
    AppSpinnerBig,
    QuickTransferSummaryItem,
    addPrecision,
    IconSendAction,
    AppSkeleton,
  },
  props: {
    isValid: {
      type: Boolean,
      required: true,
    },
    isCalculatingQuote: {
      type: Boolean,
      required: true,
      default: false,
    },
  },

  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const resourcesStore = useResourcesStore()
    const sendMoneyStore = useSendMoneyStore()
    const quickTransferStore = useQuickTransferStore()

    const isProcessingTransaction = computed(() => quickTransferStore.isProcessingTransaction)

    const recipient = computed(() => sendMoneyStore.form.recipient)

    const shouldShowRateAlert = computed(() => sendMoneyStore.didRateChange)

    const form = computed(() => sendMoneyStore.form)

    const isAcceptedTermsAndConditions = ref(false)

    const isQuoteDataInvalid = computed(
      () => !form.value.selectedQuote && !form.value.quoteErrorFrom && !form.value.quoteErrorTo
    )

    const disableConfirmButton = computed(
      () =>
        !props.isValid ||
        !isAcceptedTermsAndConditions.value ||
        props.isCalculatingQuote ||
        isProcessingTransaction.value
    )

    const noQuoteDetails = computed(
      () =>
        !recipient.value ||
        !quickTransferStore.isValidRecipient ||
        form.value.quoteErrorFrom ||
        form.value.quoteErrorTo
    )

    const onCheck = () => {
      if (isProcessingTransaction.value) {
        return
      }
      if (props.isValid) {
        isAcceptedTermsAndConditions.value = !isAcceptedTermsAndConditions.value
      }
      emit('on-accept-terms-click', isAcceptedTermsAndConditions.value)
    }

    const termsAndConditionsURL = computed(() => {
      return resourcesStore.getTermsAndConditionsUrl
    })

    watch(
      () => props.isValid,
      (isValid) => {
        if (!isValid) {
          isAcceptedTermsAndConditions.value = false
        }
      }
    )

    watch(
      () => sendMoneyStore.getConfirmedRate(),
      (confirmedRate) => {
        if (confirmedRate === null && isAcceptedTermsAndConditions) {
          sendMoneyStore.setConfirmedRate()
        }
      }
    )

    return {
      isQuoteDataInvalid,
      isProcessingTransaction,
      disableConfirmButton,
      isValidRecipient: computed(() => quickTransferStore.isValidRecipient),
      noQuoteDetails,
      $t,
      isAcceptedTermsAndConditions,
      termsAndConditionsURL,
      form,
      recipient,
      shouldShowRateAlert,
      onCheck,
      addPrecision,
      formatCurrency,
    }
  },
}
</script>

<style scoped>
.quick-transfer-summary-wrapper {
  @apply flex flex-col bg-white rounded-xl mx-auto p-8;
  flex: 1 1 0px;
  min-width: 300px;
}
.summary-no-data {
  @apply flex py-16 items-center flex-col gap-3 flex-grow;
}
.no-data-title {
  @apply font-semibold;
}
.no-data-text {
  @apply text-sm text-gray-text text-center;
}
.quote-details-title {
  @apply mb-4 text-xl text-gray-text font-semibold;
}
.spinner {
  @apply relative h-12 my-18;
}
::v-deep .input-checkbox.input-checkbox--with-label {
  @apply items-center;
}
.input-checkbox-label {
  @apply text-sm;
}
.input-checkbox--disabled {
  @apply opacity-50;
}

::v-deep .card-warning {
  @apply bg-yellow-lighter mb-0;
  font-size: 0.85rem;
}

::v-deep .input-checkbox .input-checkbox-label {
  @apply text-sm;
}

::v-deep .input-checkbox.loading {
  @apply text-gray-disabled pointer-events-none;
  .input-checkbox-virtual-checkbox-check {
    @apply border-gray-disabled;
  }
  .link {
    @apply text-gray-disabled;
  }
  &.input-checkbox--checked
    .input-checkbox-virtual-checkbox
    .input-checkbox-virtual-checkbox-check {
    @apply bg-gray-disabled;
  }
}

::v-deep .input-checkbox.input-checkbox--checked.input-checkbox--disabled .input-checkbox-label {
  @apply bg-transparent;
}

::v-deep .input-checkbox--checked .input-checkbox-virtual-checkbox:after {
  @apply hidden;
}

::v-deep
  .input-checkbox.input-checkbox--checked
  .input-checkbox-virtual-checkbox
  .input-checkbox-virtual-checkbox-check {
  @apply bg-dark-bg;
}

.terms-and-conditions-checkbox {
  @apply mb-3 mt-auto;
}
.total-to-pay {
  @apply bg-gray-200 rounded-lg p-2 flex justify-between;
}
.total-to-pay-text {
  @apply text-grayblue-300 font-semibold;
}
.total-to-pay-amount {
  @apply text-grayblue-400 font-semibold;
}
::v-deep .qt-skeleton-loader .skeleton {
  @apply bg-gray-500 rounded-full;
}
</style>

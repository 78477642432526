import { useI18nStore } from "@galileo/stores"
import { computed, onMounted, ref } from "@vue/composition-api"
import getIndustries from "@galileo/api/launchpad/resources/industries/get"
import getIndustriesCategories from '@galileo/api/launchpad/resources/industrycategories/get'
import { LaunchpadError } from "@galileo/api/types"
import { BaseFetchComposableConfig } from "../interfaces"
import { IndustryType } from "@galileo/models/Industry/industryType"
import { IndustryCategory } from "@galileo/models/Industry/industryCategory"

/**
 * Fetches industries and industry categories and provides reactive API for them.
 */
export const useIndustries = ({ immediate }: BaseFetchComposableConfig = { immediate: false }) => {
  const { localeLanguagePart } = useI18nStore()
  const loading = ref(false)
  const error = ref<string | null>(null)

  const _categories = ref<IndustryCategory[]>([])

  const industries = computed(() => {
    return _categories.value
      .filter((c) => !c.isDeleted)
      .map((c) => ({
        name: c.description,
        id: c.categoryId,
      }))
  })

  const selectedIndustry = ref<number | null>(null)
  const selectedSubIndustry = ref<number | null>(null)

  const _industries = ref<IndustryType[]>([])

  const subIndustries = computed(() => {
    return _industries.value
      .filter((i) => !i.isDeleted && i.industryCategoryId === selectedIndustry.value)
  })

  const fetchIndustries = async () => {
    loading.value = true
    try {
      const categories = await getIndustriesCategories.exec()
      _categories.value = categories.data.data

      const subIndustries = await getIndustries.exec({ lang: localeLanguagePart })
      _industries.value = subIndustries.data
    } catch (e) {
      error.value = (e as LaunchpadError).message
    } finally {
      loading.value = false
    }
  }

  onMounted(() => {
    if (immediate) {
      fetchIndustries()
    }
  })

  return {
    industries,
    subIndustries,
    selectedIndustry,
    selectedSubIndustry,
    loading,
    error,

    fetchIndustries,
  }
}

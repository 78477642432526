<template>
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      v-if="!hideBackground"
      d="M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z"
      fill="#DAE8F5"
    />
    <path
      d="M20 10.25C18.0716 10.25 16.1866 10.8218 14.5832 11.8932C12.9798 12.9645 11.7301 14.4873 10.9922 16.2688C10.2542 18.0504 10.0611 20.0108 10.4373 21.9021C10.8136 23.7934 11.7422 25.5307 13.1057 26.8943C14.4693 28.2579 16.2066 29.1865 18.0979 29.5627C19.9892 29.9389 21.9496 29.7458 23.7312 29.0078C25.5127 28.2699 27.0355 27.0202 28.1068 25.4168C29.1782 23.8134 29.75 21.9284 29.75 20C29.7473 17.415 28.7192 14.9366 26.8913 13.1087C25.0634 11.2808 22.585 10.2527 20 10.25ZM20 28.25C18.3683 28.25 16.7733 27.7661 15.4165 26.8596C14.0598 25.9531 13.0024 24.6646 12.378 23.1571C11.7536 21.6496 11.5902 19.9908 11.9085 18.3905C12.2269 16.7902 13.0126 15.3202 14.1664 14.1664C15.3202 13.0126 16.7902 12.2268 18.3905 11.9085C19.9909 11.5902 21.6497 11.7536 23.1571 12.378C24.6646 13.0024 25.9531 14.0598 26.8596 15.4165C27.7662 16.7733 28.25 18.3683 28.25 20C28.2475 22.1873 27.3775 24.2843 25.8309 25.8309C24.2843 27.3775 22.1873 28.2475 20 28.25ZM24.5 20C24.5 20.1989 24.421 20.3897 24.2803 20.5303C24.1397 20.671 23.9489 20.75 23.75 20.75H20.75V23.75C20.75 23.9489 20.671 24.1397 20.5303 24.2803C20.3897 24.421 20.1989 24.5 20 24.5C19.8011 24.5 19.6103 24.421 19.4697 24.2803C19.329 24.1397 19.25 23.9489 19.25 23.75V20.75H16.25C16.0511 20.75 15.8603 20.671 15.7197 20.5303C15.579 20.3897 15.5 20.1989 15.5 20C15.5 19.8011 15.579 19.6103 15.7197 19.4697C15.8603 19.329 16.0511 19.25 16.25 19.25H19.25V16.25C19.25 16.0511 19.329 15.8603 19.4697 15.7197C19.6103 15.579 19.8011 15.5 20 15.5C20.1989 15.5 20.3897 15.579 20.5303 15.7197C20.671 15.8603 20.75 16.0511 20.75 16.25V19.25H23.75C23.9489 19.25 24.1397 19.329 24.2803 19.4697C24.421 19.6103 24.5 19.8011 24.5 20Z"
      fill="#006CE0"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconFundAction',
  props: {
    hideBackground: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<template>
  <div class="flex flex-col">
    <p class="sub-text">{{ $t('ComponentPaymentMethodDropdown.PlaceholderText').value }}</p>
    <div class="balance-info">
      <p :class="dropdownDisabled ? 'text-gray-disabled' : 'text-grayblue-400'" class="label">
       <span v-if="isFundsOnBalance">{{ currencyFrom }}</span> {{ option.title }}
      </p>
      <p
        v-if="isFundsOnBalance"
        :class="dropdownDisabled ? 'text-gray-disabled' : 'text-grayblue-300'"
        class="sub-text"
      >
        - {{ availableBalanceText }}
      </p>
    </div>
  </div>
</template>

<script>
import { useBalancesStore, useDeviceStore, useI18nStore, useSendMoneyStore } from '@galileo/stores'
import { formatShortenCurrency, formatCurrency } from '@galileo/composables/useCurrency'
import { PAYMENT_METHODS } from '@galileo/constants/sendMoneyFlow.const'
import { computed } from '@vue/composition-api'

export default {
  name: 'BankAccountSelectedOption',
  props: {
    option: {
      type: Object,
      required: true,
      default: {},
    },
    dropdownDisabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { $t } = useI18nStore()
    const sendMoneyStore = useSendMoneyStore()
    const balancesStore = useBalancesStore()

    const currencyFrom = computed(() => sendMoneyStore.form.currencyFrom)

    const getAvailableBalanceOfCurrencyFrom = () => {
      const balance = balancesStore.userActiveBalanceList.find(
        (b) => b.value === currencyFrom.value
      )
      if (balance) return balance.availableBalance
      return 0
    }

    const isFundsOnBalance = computed(() => props.option.value === PAYMENT_METHODS.FUNDS_ON_BALANCE)
    const availableBalanceText = computed(() => {
      const amount = getAvailableBalanceOfCurrencyFrom()
      const formattedAmount = formatCurrency(currencyFrom.value, amount, useDeviceStore().locale)

      return $t('PaymentMethodOption.AvailableBalance', {
        amount: formattedAmount,
        currency: currencyFrom.value,
      }).value
    })

    return {
      $t,
      formatShortenCurrency,
      isFundsOnBalance,
      availableBalanceText,
      currencyFrom,
    }
  },
}
</script>

<style scoped>
.label {
  @apply leading-5;
}
.balance-info {
  @apply flex items-baseline gap-2;
  @apply text-grayblue-400;
}
.sub-text {
  @apply text-xs leading-3;
  @apply mb-1;
}
</style>

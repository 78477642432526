<template>
  <AppListItem :disabled="true" :id="bankAccountId">
    <template #figure>
      <AppFigure class="xe-figure" theme="gray">
        <AppIcon name=" "> <IconFinance /> </AppIcon>
      </AppFigure>
    </template>
    <template #left>
      <AppListItemTitle>
        {{ bankAccount.alias || bankAccount.accountName }}
        {{ bankAccount.maskedAccountNumber }}
      </AppListItemTitle>
      <AppListItemCaption class="bank-caption">
        <div>
          <span class="acc-currency mr-1">{{ bankAccount.currency }}</span>
          <span v-if="bankAccount.alias">{{ bankAccount.bankName }}</span>
        </div>
        <span v-if="isDisabled">
          {{ $t('ComponentBankSelectionDropdown.CurencyNotSupportedText').value }}
        </span>
        <span
          v-else-if="
            bankAccount.verificationStatus === 'Unverified' ||
            bankAccount.verificationStatus === 'PendingReview'
          "
          class="pending-verification"
        >
          {{ $t('ComponentBankSelectionDropdown.PendingVerificationText').value }}
        </span>
      </AppListItemCaption>
    </template>
    <template #action>
      <button v-if="!isDisabled" class="edit-button" @click="onDetails">
        <AppIcon name=" ">
          <IconEdit />
        </AppIcon>
      </button>
    </template>
  </AppListItem>
</template>

<script>
import { useI18nStore, useSendMoneyStore } from '@galileo/stores'
import {
  AppIcon,
  AppListItem,
  AppFigure,
  AppListItemTitle,
  AppListItemCaption,
  AppRipple,
} from '@oen.web.vue2/ui'
import { IconEdit, IconFinance } from '@oen.web.vue2/icons'
import { ref, onMounted } from '@vue/composition-api'
export default {
  name: 'BankAccountOption',
  emits: ['onDetails'],
  components: {
    AppIcon,
    AppListItem,
    AppFigure,
    AppListItemTitle,
    AppListItemCaption,
    IconEdit,
    IconFinance,
    AppRipple,
  },
  props: {
    bankAccount: {
      type: Object,
      required: true,
      default: {},
    },
  },
  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const sendMoneyStore = useSendMoneyStore()

    const bankAccountId = ref(`dd-${props.bankAccount.id}`)

    const onDetails = (e) => {
      emit('onDetails', props.bankAccount)
      e.stopPropagation()
    }

    const isDisabled = ref(props.bankAccount.currency !== sendMoneyStore.form.currencyFrom)

    onMounted(() => {
      isDisabled.value = props.bankAccount.currency !== sendMoneyStore.form.currencyFrom
      const disabledOption = document.querySelector(`#${bankAccountId.value}`)
      if (disabledOption && isDisabled.value) {
        disabledOption.closest('.p-dropdown-item').classList.add('disabled-option')
      }
    })

    return {
      $t,
      onDetails,
      bankAccountId,
      isDisabled,
    }
  },
}
</script>

<style scoped>
::v-deep .prime-dropdown .p-dropdown-panel .p-dropdown-item .dropdown-option {
  @apply px-3;
}
::v-deep .list-item-content {
  @apply py-0;
}
::v-deep .list-item-figure {
  @apply flex;
}
::v-deep .list-item-caption {
  @apply flex flex-col justify-start gap-1;
}
.pending-verification {
  color: #b57600;
}
.xe-figure {
  @apply w-12 h-12;
  border-radius: 50%;
}
.edit-button {
  @apply p-2 rounded-tr;
}
.edit-button:hover {
  background: #e8ebed;
}

.acc-currency {
  @apply px-1 font-semibold;
  border-radius: 2px;
  background-color: #e8ebed;
}
@screen xs {
  ::v-deep .list-item-title {
    max-width: 90%;
    word-wrap: break-word;
    white-space: normal; /* Allows text to wrap */
    overflow-wrap: break-word; /* Ensures long words break to fit the container */
    overflow: hidden; /* Ensures content does not overflow the container */
  }
}
</style>
